import { Card, Divider, Input, List, Space, Button, Tooltip, Popover } from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import { getMarketLogo } from "../../../library/helpers/utils";
import { TagContext } from "../../../library/hooks/context/TagContext";

import { PlusOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import { addAppToTag, deleteApp, searchAppsByText } from "../../../library/helpers/app-select-helpers";
import MarketSelect from "../../selects/MarketSelect";

let openPopoverVisible = false;

export default function MyAppsOverlay({ setVisible = false, forwardRef = null, onlyMyApps = false, onlyCompApps = false, wizardSearch = false }) {
  const tagContext = useContext(TagContext);
  const dropdownRef = useRef();
  const inputRef = useRef();
  const [myApps, setMyApps] = useState([]);
  const [competitorApps, setCompetitorApps] = useState([]);
  const [appSearchList, setAppSearchList] = useState([]);
  const [filteredSearchList, setFilteredSearchList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState({});

  const appMin = false;

  // Searching status (whether there is pending API request)
  const [isSearching, setIsSearching] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [popoverStates, setPopoverStates] = useState([]);

  const handleButtonClick = (index) => {
    const updatedStates = [...popoverStates];
    updatedStates[index] = false;
    setPopoverStates(updatedStates); // Update the state array to close the Popover at the specified index
  };

  function onAppSelect(value, type) {
    const app = {
      ...value,
      appId: value.appid,
    };

    let appList = [];
    if (type == "competitorapps") {
      appList = tagContext.competitorAppList;
    }
    if (type == "myapps") {
      appList = tagContext.appList;
    }

    const tagSlug = tagContext.selectedTag.slug;
    addAppToTag({
      tagSlug,
      value,
      appType: type,
      onSuccess: () => {
        if (type == "competitorapps") {
          tagContext.setCompetitorAppList([...appList, app]);
        }
        if (type == "myapps") {
          tagContext.setAppList([...appList, app]);
        }

        let allAppList = [];
        allAppList = tagContext.allAppList;

        tagContext.setAllAppList([...allAppList, app]);

        if (!tagContext.selectedApp?.title) {
          tagContext.setSelectedApp(app);
        }
      },
    });
  }

  // Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
  }

  const onDeleteApp = (id, type) => {
    const tagSlug = tagContext.selectedTag.slug;

    let appList = [];
    if (type == "competitorapps") {
      appList = tagContext.competitorAppList;
    }
    if (type == "myapps") {
      appList = tagContext.appList;
    }

    deleteApp({
      tagSlug,
      id,
      appType: type,
      onSuccess: () => {
        const newApps = appList.filter((app) => app.appId !== id);

        if (type == "competitorapps") {
          tagContext.setCompetitorAppList(newApps);
        }
        if (type == "myapps") {
          tagContext.setAppList(newApps);
        }

        let allAppList = [];
        allAppList = tagContext.allAppList;
        const newAllApps = allAppList.filter((app) => app.appId !== id);
        tagContext.setAllAppList(newAllApps);

        if (tagContext.selectedApp.appId == id) {
          if (newApps.length) {
            tagContext.setSelectedApp(newApps[0]);
          } else {
            tagContext.setSelectedApp({});
          }
        }
      },
      apps: appList,
    });
  };

  const searchCharacters = (value) => {
    setAppSearchList(tagContext.appList.filter(({ title }) => title.toLowerCase().includes(value.toLowerCase())));

    if (value.length > 0) {
      setIsSearching(true);
      searchAppsByText({
        value,
        market: selectedMarket.id,
        onSuccess: (response) => {
          setAppSearchList(response);
          setIsSearching(false);
        },
      });
    } else {
      setAppSearchList([]);
      setIsSearching(false);
    }
  };

  function updateAppList(searchTerm) {
    if (!searchTerm || onlyMyApps || onlyCompApps) {
      if (selectedMarket.id != "ALL" && !wizardSearch) {
        setMyApps(tagContext.appList.filter(({ market }) => market === selectedMarket.id));
        setCompetitorApps(tagContext.competitorAppList.filter(({ market }) => market === selectedMarket.id));
      } else {
        setMyApps(tagContext.appList);
        setCompetitorApps(tagContext.competitorAppList);
      }
    } else {
      setMyApps(tagContext.appList.filter(({ title, market }) => title.toLowerCase().includes(searchTerm) && market === selectedMarket.id));
      setCompetitorApps(tagContext.competitorAppList.filter(({ title, market }) => title.toLowerCase().includes(searchTerm) && market === selectedMarket.id));
    }
  }

  useEffect(() => {
    //console.log("tagContext.marketList", tagContext.marketList);
    if (!tagContext.marketList?.length) {
      return;
    }
    const list = tagContext.marketList.filter(({ id }) => id !== "MAC");
    const newArray = [{ id: "ALL", label: "All" }].concat(list);
    setMarkets(newArray);
    setSelectedMarket(newArray[0]);
  }, [tagContext.marketList]);

  useEffect(() => {
    updateAppList(debouncedSearchTerm);
    setIsSearching(!!debouncedSearchTerm);
    debouncedSearchTerm && searchCharacters(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setMyApps(tagContext.appList);
    updateAppList(inputRef.current.input.value);
  }, [tagContext.appList, selectedMarket]);

  useEffect(() => {
    //console.log("selectedMarket", selectedMarket);
    if (selectedMarket && inputRef.current.input.value.length > 0) {
      searchCharacters(inputRef.current.input.value);
    }
  }, [selectedMarket]);

  useEffect(() => {
    setCompetitorApps(tagContext.competitorAppList);
    updateAppList(inputRef.current.input.value);
  }, [tagContext.competitorAppList]);

  useEffect(() => {
    if (!appSearchList?.length) {
      setFilteredSearchList([]);
    } else {
      /* setFilteredSearchList(
				appSearchList.filter(({ market }) => market === selectedMarket.id)
			);*/
      setFilteredSearchList(appSearchList);
    }
  }, [appSearchList, selectedMarket]);

  const getAppTitle = (app, type) => {
    const logo = getMarketLogo(app.market);
    return (
      <div style={{ display: "flex" }}>
        <Tooltip title={app.title}>
          <div className="appicon">
            <img src={app.icon} alt="icon" className="row-icon" style={{ borderRadius: "10px" }} />
            <img
              style={{
                height: "15px",
                width: "15px",
                position: "absolute",
                top: "30px",
                left: "-6px",
                borderRadius: "0px",
              }}
              src={logo}
            />
          </div>
        </Tooltip>
        {!appMin && (
          <div className="title-info">
            <span className="title-name" style={{ fontSize: "11px" }}>
              {app.title}
            </span>
            <p className="subtitle-name" style={{ fontSize: "11px" }}>
              {" "}
              {app.seller}&nbsp;[{app.appId}]
            </p>
          </div>
        )}
        <Button
          className="app-add-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDeleteApp(app.appId, type);
          }}
          icon={<CloseCircleTwoTone twoToneColor="#F27405" />}
        />
      </div>
    );
  };

  const getAppTitleAdd = (app, index) => {
    const appIds = tagContext.allAppList.map((app) => app.appId);

    const handleClick = () => {
      onAppSelect(app, onlyMyApps ? "myapps" : "competitorapps");
    };

    let foundapp = false;
    if (appIds.includes(app.appid)) {
      foundapp = true;
    }

    const logo = getMarketLogo(app.market);
    return (
      <>
        <div style={{ display: "flex" }} onClick={!foundapp && (onlyMyApps || onlyCompApps) ? handleClick : null}>
          <div className="appicon">
            <img src={app.icon} alt="icon" className="row-icon" style={{ borderRadius: "10px" }} />
            <img
              style={{
                height: "15px",
                width: "15px",
                position: "absolute",
                top: "30px",
                left: "-6px",
                borderRadius: "0px",
              }}
              src={logo}
            />
          </div>

          <div className="title-info">
            <span className="title-name" style={{ fontSize: "11px" }}>
              {app.title}
            </span>
            <p className="subtitle-name" style={{ fontSize: "11px" }}>
              {" "}
              {app.seller}&nbsp;[{app.appid}]
            </p>
          </div>
          {!foundapp && !onlyMyApps && !onlyCompApps && (
            <Popover
              trigger="click"
              open={popoverStates[index]}
              onOpenChange={(visible) => {
                const updatedStates = [...popoverStates];
                updatedStates[index] = visible;
                openPopoverVisible = visible;
                setPopoverStates(updatedStates); // Update the state array when the Popover at the specified index is opened or closed
              }}
              content={
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAppSelect(app, "myapps");
                      handleButtonClick(index);
                    }}
                  >
                    Primary App
                  </Button>{" "}
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAppSelect(app, "competitorapps");
                      handleButtonClick(index);
                    }}
                  >
                    Competitor App
                  </Button>
                </>
              }
            >
              <Button className="app-add-button">
                <PlusOutlined />
              </Button>
            </Popover>
          )}
        </div>
      </>
    );
  };

  const handleAppSelect = (item) => {
    if (!wizardSearch) {
      tagContext.setSelectedApp(item);
      setVisible(false);
    }
  };

  const clickOutsideListener = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current.contains(event.target) && forwardRef?.current && !forwardRef.current.contains(event.target)) {
          if (!openPopoverVisible) {
            setVisible(false);
          } else {
            openPopoverVisible = false;
          }
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  clickOutsideListener(dropdownRef);
  return (
    <div ref={dropdownRef} className={"nav-tabs__dropdown-overlay nav-tabs__dropdown-overlay--apps"} style={{ minWidth: "70vw" }}>
      <Space
        align="center"
        style={{
          padding: "0 8px 4px",
        }}
      >
        <Input ref={inputRef} className="search-bar" placeholder="Search Apps by name or id" onChange={(e) => setSearchTerm(e.target.value)} style={{ marginLeft: "0.5vw", width: "300px" }} allowClear autoFocus /> <MarketSelect markets={markets} selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />
      </Space>
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <div className="filters filters--tag-manager"></div>
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      {myApps.length > 0 && !onlyCompApps && (
        <>
          <h3
            className="section-header"
            style={{
              paddingLeft: "25px",
              marginBottom: 0,
              marginTop: 10,
              lineHeight: "30px",
            }}
          >
            Primary Apps
          </h3>
          <List
            style={{ maxHeight: "50vh", overflow: "auto", overflowX: "hidden" }}
            grid={{
              gutter: 2,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4,
            }}
            dataSource={myApps}
            renderItem={(item) => (
              <List.Item onClick={() => handleAppSelect(item)} className="app-select-item">
                <Card
                  className="app-select-card"
                  style={{
                    width: "270px",
                    height: "60px",
                    marginTop: 0,
                    padding: "5px",
                    paddingBottom: appMin ? 10 : 0,
                  }}
                >
                  {getAppTitle(item, "myapps")}
                </Card>
              </List.Item>
            )}
          />

          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        </>
      )}
      {competitorApps.length > 0 && !onlyMyApps && (
        <>
          <h3 className="section-header" style={{ paddingLeft: "25px", marginBottom: 0, lineHeight: "30px" }}>
            Competitor Apps
          </h3>
          <List
            style={{ maxHeight: "50vh", overflow: "auto", overflowX: "hidden" }}
            grid={{
              gutter: 2,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4,
            }}
            dataSource={competitorApps}
            renderItem={(item) => (
              <List.Item style={{ marginBottom: 0 }} onClick={() => handleAppSelect(item)} className="app-select-item">
                <Card
                  className="app-select-card"
                  style={{
                    width: "270px",
                    height: "60px",
                    marginTop: 0,
                    padding: "5px",
                    paddingBottom: appMin ? 10 : 0,
                  }}
                >
                  {getAppTitle(item, "competitorapps")}
                </Card>
              </List.Item>
            )}
          />
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        </>
      )}
      {searchTerm.length > 0 && (
        <>
          <h3 className="section-header" style={{ paddingLeft: "25px", marginBottom: 0, lineHeight: "30px" }}>
            Search Results <span style={{ fontSize: 10 }}>(If you can't find your app please send us a feedback)</span>
          </h3>
          <List
            loading={isSearching}
            style={{ maxHeight: "50vh", overflow: "auto", overflowX: "hidden" }}
            grid={{
              gutter: 2,
              sm: filteredSearchList.length < 2 ? filteredSearchList.length : 2,
              md: filteredSearchList.length < 3 ? filteredSearchList.length : 3,
              lg: filteredSearchList.length < 3 ? filteredSearchList.length : 3,
              xl: filteredSearchList.length < 4 ? filteredSearchList.length : 4,
              xxl: filteredSearchList.length < 6 ? filteredSearchList.length : 6,
            }}
            dataSource={filteredSearchList}
            renderItem={(item, index) => (
              <List.Item style={{ marginBottom: 0 }} className="app-select-item">
                <Card
                  className="app-select-card"
                  style={{
                    width: "270px",

                    height: "60px",
                    marginTop: 0,
                    padding: "5px",
                    paddingBottom: appMin ? 10 : 0,
                  }}
                >
                  {getAppTitleAdd(item, index)}
                </Card>
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
}
