import { Menu, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../library/helpers/account-helper-functions";
import { TagContext } from "../../../library/hooks/context/TagContext";
import FeedbackForm from "../../views/Account/FeedbackAndSupport/FeedbackForm";

export default function ProfileMenu() {
  const navigate = useNavigate();
  const tagContext = useContext(TagContext);
  const [allowedKeywords, setAllowedKeywords] = useState(null);
  const [modalFeedbackOpen, setModalFeedbackOpen] = useState(false);
  const handleMenuClick = ({ item, domEvent }) => {
    domEvent.preventDefault();
    switch (item.props.value) {
      case "logout":
        logout({
          tagContext: tagContext,
          onSuccess: () => {
            window.location.href = "https://www.applyzer.com";
            //window.location('/login');
          },
        });
        break;
      case "edit_profile":
        navigate("/account");
        break;
      case "admin":
        navigate("/admin");
        break;
      case "pricing":
        navigate("/pricing");
        break;
      case "tutorials":
        navigate("/tutorials");
        break;
      case "support":
        setModalFeedbackOpen(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let keywords = 25;
    tagContext.userSubscriptions.forEach((subscription) => {
      if (subscription["plan.type"] === "PRO") {
        let mcount = 0;
        if (subscription.limits == "null") {
          mcount = JSON.parse(subscription["plan.limits"]).keywords;
          if (mcount == -1) {
            keywords = "unlimited";
          } else {
            keywords = mcount;
          }
        } else {
          mcount = JSON.parse(subscription.limits).keywords;
          if (mcount == -1) {
            keywords = "unlimited";
          } else {
            keywords = mcount;
          }
        }
      }
    });
    setAllowedKeywords(keywords);
  }, [tagContext.userSubscriptions]);
  const adminMenu = {
    label: "Admin",
    value: "admin",
    key: "6",
  };
  return (
    <>
      <Menu
        style={{ width: 200 }}
        onClick={handleMenuClick}
        items={[
          {
            label: `${tagContext.userStats?.keywords || "-"} / ${allowedKeywords} Keywords`,
            key: "1",
          },
          {
            type: "divider",
          },
          {
            label: "Edit Profile",
            value: "edit_profile",
            key: "3",
          },
          {
            label: "Support",
            value: "support",
            key: "4",
          },
          {
            label: "Tutorials",
            value: "tutorials",
            key: "5",
          },
          ...(tagContext.userData.groups?.includes("admin") ? [adminMenu] : []),
          {
            type: "divider",
          },
          {
            label: "Logout",
            value: "logout",
            key: "7",
          },
        ]}
      />
      <Modal bodyStyle={{ padding: 0 }} centered open={modalFeedbackOpen} footer={null} onCancel={() => setModalFeedbackOpen(false)}>
        <FeedbackForm setVisible={setModalFeedbackOpen} />
      </Modal>
    </>
  );
}
/*
,
                {
                    label: 'Upgrade Subscription',
                    value: 'pricing',
                    key: '4',
                   
                },
                {
    type: 'divider',
  }
*/
