export const tagsOperations = {
  detail: "detail",
  list: "list",
  createTag: "create",
  deleteTag: "delete",
  editTag: "rename",
  addKeywords: "keyword/add",
  importKeywords: "keyword/import",
  deleteKeywords: "keyword/delete",
  deleteAllKeywords: "keyword/deleteall",
  setKeywordGroups: "keyword/group/set",
  groupsList: "group/list",
  addKeywordGroup: "group/add",
  addApp: "app/add",
  deleteApp: "app/delete",
  exportDetails: "export/details",
  exportSetDetails: "export/set",
  setTagSetting: "setting/set",
  getTagSetting: "setting/get",
  notificationDetails: "notification/details",
  notificationSetDetails: "notification/set",
};

export const generalOperations = {
  markets: "markets",
  countries: "countries",
  searchAppsById: "app/searchbyid",
  searchAppsByText: "app/search",
  initConfig: "config",
  feedback: "feedback",
};

export const userOperations = {
  create: "create",
  verify: "verify",
  slackverify: "slackverify",
  delete: "delete",
  changePassword: "password/change",
  forgotPassword: "password/forgot",
  resetPassword: "password/reset",
};

export const subscriptionOperations = {
  allPlans: "plans",
  userDetails: "user",
  activeSubscription: "active",
};

export const keywordOperations = {
  rankings: "rankingsrel",
  worldChart: "rankings/worldchart",
  genetalConfig: "general/config",
  generalCategories: "general/categories",
  trafficscoreHistory: "trafficscorehistory",
  keywordHistory: "rankinghistory",
  keywordResultsRanking: "rankingresults",
  autocompleteimport: "autocomplete/import",
};

export const rankingOperations = {
  app: "app",
  applast24hours: "app/last24hours",
};

export const competitorOperations = {
  generate: "generate",
};
