import React, { useContext, useState, useEffect, useRef } from "react";
import { Space, Input, Collapse, Tooltip, Select } from "antd";

import "../../../assets/scss/components/_datagrid.scss";
import { postRequest } from "../../../library/api/middleware";
import { findInDict } from "../../../library/helpers/utils";
import { Tag } from "antd";
import { TagContext } from "../../../library/hooks/context/TagContext";
import { addKeywordsToTag } from "../../../library/helpers/keywords-helper-functions";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { configureCountrySelectOptions } from "../../../library/helpers/keywords-helper-functions";

const { CheckableTag } = Tag;

export default function AddKeywords({ device }) {
  const tagContext = useContext(TagContext);
  const [resultData, setResultData] = useState(null);

  const [tags, setTags] = useState(null);
  const [tagsList, setTagsList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const inputRef = useRef(null);
  const { Search } = Input;

  const [savingData, setSavingData] = useState(false);
  const [selectedTags, setSelectedTags] = useState(null);

  const [options, setOptions] = useState("");
  const [defaultValue, setDefaultValue] = useState(1);
  const [countryValue, setCountryValue] = useState(1);

  const genExtraDisabled = false;

  const addKeywords = (values) => {
    let countries = [findInDict(tagContext.countryList, countryValue).code];
    let market = tagContext.selectedApp.market;
    addKeywordsToTag({
      tagSlug: tagContext.selectedTag.slug,
      market: market,
      country: countries,
      keywords: [values],
      groups: ["Generic"],
      onSuccess: (response) => {},
      onError: (error) => console.warn(error),
    });
  };

  const handleChange = (tag, checked) => {
    //const nextSelectedTags = [...selectedTags, tag];
    addKeywords(tag);
    setSelectedTags(tag);
  };

  useEffect(() => {
    console.log("tags", tagsList);
  }, [tagsList]);

  useEffect(() => {
    if (selectedTags) {
      const nextSelectedTags = [...tagsList, selectedTags];
      setTagsList(nextSelectedTags);
    }

    //console.log("selectedTags2", selectedTags2);
  }, [selectedTags]);

  function getData() {
    setLoadingData(true);

    postRequest({
      operation: "quickgenerate",
      endpoint: "keyword",
      data: {
        appid: tagContext.selectedApp?.appid,
        countryid: 1,
        competitorapps: [],
      },
      callback: (responseData) => {
        setResultData(responseData);
        setLoadingData(false);
      },
      errorCallback: () => {
        setResultData(null);
        setLoadingData(false);
      },
    });
  }

  const addMultipleKeywords = (values, groups) => {
    setSavingData(true);
    let countries = [findInDict(tagContext.countryList, countryValue).code];
    tagContext.openNotification("", "Keywords are getting added.");
    addKeywordsToTag({
      tagSlug: tagContext.selectedTag.slug,
      market: market,
      country: countries,
      device: device,
      keywords: values,
      groups: groups,
      onSuccess: (response) => {
        //setAddedKeywords2(response);
        //tagContext.setContextValue("subscriptionFetched", false);
        //tagContext.openNotification("", "Keywords added.");
        setSavingData(false);
      },
      onError: (error) => {
        setSavingData(false);
        tagContext.openNotification("", "Error while adding keywords.", "error");
        console.warn(error);
      },
    });
  };

  function onChange() {
    setResultData(null);
    getData();
  }

  const genExtra = (mid) => (
    <>
      <Tooltip title="Add all keywords to your list">
        <AppstoreAddOutlined
          disabled={genExtraDisabled}
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:

            event.stopPropagation();
            if (genExtraDisabled) {
              return;
            }
            let values = [];

            values = resultData.response[mid].result.map((tag) => {
              return tag;
            });

            if (values.length > 0) {
              addMultipleKeywords(values, ["Generic"]);
            }
          }}
        />
      </Tooltip>
    </>
  );

  useEffect(() => {
    if (!tagContext.countryList.length) {
      return;
    }

    const configuredCountrySelectOptions = configureCountrySelectOptions(tagContext.countryList, []);

    const getOptions = (countries) => {
      return countries.map((country) => {
        return (
          <Option className="country-select__option" value={country.id} key={country.id} label={country.name}>
            <img className="country-select__flag" src={country.src} />
            <span className="country-select__text">
              {country.name} {country?.keywords ? "" : ""}
            </span>
          </Option>
        );
      });
    };

    setOptions(getOptions(configuredCountrySelectOptions.otherCountries));
  }, [tagContext.countryList, tagContext.tagKeywordData]);

  function checkTag(tag) {
    console.log("test");
    let mresult = tagsList.includes(tag);
    console.log("mresult", tag, mresult);
    return mresult;
  }
  //selectedTags.includes(tag)
  useEffect(() => {
    if (!resultData) {
      return;
    }
    /*
		console.log("data");
		let counter = 0;
		const mtags = resultData.response.map((tag) => {
			return (
				<CheckableTag
					key={tag}
					onChange={(checked) => handleChange(tag, checked)}
				>
					{tag}
				</CheckableTag>
			);
		});
		setTags(mtags);
    */
  }, [resultData]);

  useEffect(() => {
    onChange();
  }, [countryValue]);

  const onChangeCountry = (value) => {
    console.log("value", value);
    setCountryValue(value);
  };

  return (
    <>
      {options && (
        <>
          <p
            style={{
              paddingTop: "10px",
              fontSize: "14pt",
              color: "black",
            }}
          >
            Suggested Keywords for&nbsp;&nbsp;
            <Select defaultValue={defaultValue} value={countryValue} className="country-select" showSearch placeholder="Select a Country" optionFilterProp="label" onChange={onChangeCountry}>
              {options}
            </Select>
          </p>
        </>
      )}
      {countryValue && (
        <>
          <div style={{ paddingTop: 35 }}>
            <Spin spinning={loadingData}>
              {resultData?.response?.length > 0 && (
                <>
                  <Space style={{ width: "100%", margin: "auto" }} direction="vertical" size={"large"}>
                    <Search style={{ width: 350 }} ref={inputRef} onPressEnter={onChange} onSearch={onChange} placeholder="Add Keywords (comma separated)" enterButton="Add" size="medium" loading={savingData} />
                  </Space>
                  <p
                    style={{
                      paddingTop: "10px",
                      fontSize: "10pt",
                      color: "gray",
                      display: "none",
                    }}
                  >
                    Select words to add to your keyword list.
                  </p>
                </>
              )}
              <Space style={{ width: "100%", paddingTop: 10 }} wrap>
                {resultData?.response?.map((tag) => (
                  <CheckableTag key={tag} checked={tagsList.includes(tag)} onChange={(checked) => handleChange(tag, checked)}>
                    {tag} {!tagsList.includes(tag) && <span style={{ fontSize: "14pt" }}>+</span>}
                  </CheckableTag>
                ))}
              </Space>
            </Spin>
          </div>
        </>
      )}
    </>
  );
}
