import US from '../../assets/images/flags/us.png';
import AR from '../../assets/images/flags/ar.png';
import AU from '../../assets/images/flags/au.png';
import AT from '../../assets/images/flags/at.png';
import BE from '../../assets/images/flags/be.png';
import BR from '../../assets/images/flags/br.png';
import CA from '../../assets/images/flags/ca.png';
import CL from '../../assets/images/flags/cl.png';
import CN from '../../assets/images/flags/cn.png';
import CO from '../../assets/images/flags/co.png';
import CR from '../../assets/images/flags/cr.png';
import HR from '../../assets/images/flags/hr.png';
import CZ from '../../assets/images/flags/cz.png';
import DK from '../../assets/images/flags/dk.png';
import DE from '../../assets/images/flags/de.png';
import SV from '../../assets/images/flags/sv.png';
import ES from '../../assets/images/flags/es.png';
import FI from '../../assets/images/flags/fi.png';
import FR from '../../assets/images/flags/fr.png';
import GR from '../../assets/images/flags/gr.png';
import GT from '../../assets/images/flags/gt.png';
import HK from '../../assets/images/flags/hk.png';
import HU from '../../assets/images/flags/hu.png';
import IN from '../../assets/images/flags/in.png';
import ID from '../../assets/images/flags/id.png';
import IE from '../../assets/images/flags/ie.png';
import IL from '../../assets/images/flags/il.png';
import IT from '../../assets/images/flags/it.png';
import KR from '../../assets/images/flags/kr.png';
import KW from '../../assets/images/flags/kw.png';
import LB from '../../assets/images/flags/lb.png';
import LU from '../../assets/images/flags/lu.png';
import MY from '../../assets/images/flags/my.png';
import MX from '../../assets/images/flags/mx.png';
import NL from '../../assets/images/flags/nl.png';
import NZ from '../../assets/images/flags/nz.png';
import NO from '../../assets/images/flags/no.png';
import PK from '../../assets/images/flags/pk.png';
import PA from '../../assets/images/flags/pa.png';
import PE from '../../assets/images/flags/pe.png';
import PH from '../../assets/images/flags/ph.png';
import PL from '../../assets/images/flags/pl.png';
import PT from '../../assets/images/flags/pt.png';
import QA from '../../assets/images/flags/qa.png';
import RO from '../../assets/images/flags/ro.png';
import RU from '../../assets/images/flags/ru.png';
import SA from '../../assets/images/flags/sa.png';
import CH from '../../assets/images/flags/ch.png';
import SG from '../../assets/images/flags/sg.png';
import SK from '../../assets/images/flags/sk.png';
import SI from '../../assets/images/flags/si.png';
import ZA from '../../assets/images/flags/za.png';
import LK from '../../assets/images/flags/lk.png';
import SE from '../../assets/images/flags/se.png';
import TW from '../../assets/images/flags/tw.png';
import TH from '../../assets/images/flags/th.png';
import TR from '../../assets/images/flags/tr.png';
import AE from '../../assets/images/flags/ae.png';
import GB from '../../assets/images/flags/gb.png';
import VE from '../../assets/images/flags/ve.png';
import VN from '../../assets/images/flags/vn.png';
import JP from '../../assets/images/flags/jp.png';
import DO from '../../assets/images/flags/do.png';
import UY from '../../assets/images/flags/uy.png';
import PY from '../../assets/images/flags/py.png';
import NI from '../../assets/images/flags/ni.png';
import MD from '../../assets/images/flags/md.png';
import MT from '../../assets/images/flags/mt.png';
import MO from '../../assets/images/flags/mo.png';
import LT from '../../assets/images/flags/lt.png';
import LV from '../../assets/images/flags/lv.png';
import KZ from '../../assets/images/flags/kz.png';
import EC from '../../assets/images/flags/ec.png';
import EG from '../../assets/images/flags/eg.png';
import EE from '../../assets/images/flags/ee.png';
import HN from '../../assets/images/flags/hn.png';
import JM from '../../assets/images/flags/jm.png';
import AM from '../../assets/images/flags/am.png';
import BW from '../../assets/images/flags/bw.png';
import BG from '../../assets/images/flags/bg.png';
import JO from '../../assets/images/flags/jo.png';
import KE from '../../assets/images/flags/ke.png';
import MK from '../../assets/images/flags/mk.png';
import MG from '../../assets/images/flags/mg.png';
import ML from '../../assets/images/flags/ml.png';
import MU from '../../assets/images/flags/mu.png';
import NE from '../../assets/images/flags/ne.png';
import SN from '../../assets/images/flags/sn.png';
import TN from '../../assets/images/flags/tn.png';
import UG from '../../assets/images/flags/ug.png';
import DZ from '../../assets/images/flags/dz.png';
import AO from '../../assets/images/flags/ao.png';
import AI from '../../assets/images/flags/ai.png';
import AG from '../../assets/images/flags/ag.png';
import AZ from '../../assets/images/flags/az.png';
import BS from '../../assets/images/flags/bs.png';
import BH from '../../assets/images/flags/bh.png';
import BB from '../../assets/images/flags/bb.png';
import BY from '../../assets/images/flags/by.png';
import BZ from '../../assets/images/flags/bz.png';
import BM from '../../assets/images/flags/bm.png';
import BO from '../../assets/images/flags/bo.png';
import VG from '../../assets/images/flags/vg.png';
import BN from '../../assets/images/flags/bn.png';
import KY from '../../assets/images/flags/ky.png';
import CY from '../../assets/images/flags/cy.png';
import DM from '../../assets/images/flags/dm.png';
import GH from '../../assets/images/flags/gh.png';
import GD from '../../assets/images/flags/gd.png';
import GY from '../../assets/images/flags/gy.png';
import IS from '../../assets/images/flags/is.png';
import MS from '../../assets/images/flags/ms.png';
import NG from '../../assets/images/flags/ng.png';
import OM from '../../assets/images/flags/om.png';
import KN from '../../assets/images/flags/kn.png';
import LC from '../../assets/images/flags/lc.png';
import VC from '../../assets/images/flags/vc.png';
import SR from '../../assets/images/flags/sr.png';
import TZ from '../../assets/images/flags/tz.png';
import TT from '../../assets/images/flags/tt.png';
import TC from '../../assets/images/flags/tc.png';
import UZ from '../../assets/images/flags/uz.png';
import YE from '../../assets/images/flags/ye.png';
import AL from '../../assets/images/flags/al.png';
import BJ from '../../assets/images/flags/bj.png';
import BT from '../../assets/images/flags/bt.png';
import BF from '../../assets/images/flags/bf.png';
import KH from '../../assets/images/flags/kh.png';
import CV from '../../assets/images/flags/cv.png';
import TD from '../../assets/images/flags/td.png';
import CG from '../../assets/images/flags/cg.png';
import FJ from '../../assets/images/flags/fj.png';
import GM from '../../assets/images/flags/gm.png';
import GW from '../../assets/images/flags/gw.png';
import KG from '../../assets/images/flags/kg.png';
import LA from '../../assets/images/flags/la.png';
import LR from '../../assets/images/flags/lr.png';
import MW from '../../assets/images/flags/mw.png';
import MR from '../../assets/images/flags/mr.png';
import FM from '../../assets/images/flags/fm.png';
import MN from '../../assets/images/flags/mn.png';
import MZ from '../../assets/images/flags/mz.png';
import NA from '../../assets/images/flags/na.png';
import NP from '../../assets/images/flags/np.png';
import PW from '../../assets/images/flags/pw.png';
import PG from '../../assets/images/flags/pg.png';
import ST from '../../assets/images/flags/st.png';
import SC from '../../assets/images/flags/sc.png';
import SL from '../../assets/images/flags/sl.png';
import SB from '../../assets/images/flags/sb.png';
import SZ from '../../assets/images/flags/sz.png';
import TJ from '../../assets/images/flags/tj.png';
import TM from '../../assets/images/flags/tm.png';
import UA from '../../assets/images/flags/ua.png';
import ZW from '../../assets/images/flags/zw.png';
import RW from '../../assets/images/flags/rw.png';

export const flags = {
    US: US,
    AR: AR,
    AU: AU,
    AT: AT,
    BE: BE,
    BR: BR,
    CA: CA,
    CL: CL,
    CN: CN,
    CO: CO,
    CR: CR,
    HR: HR,
    CZ: CZ,
    DK: DK,
    DE: DE,
    SV: SV,
    ES: ES,
    FI: FI,
    FR: FR,
    GR: GR,
    GT: GT,
    HK: HK,
    HU: HU,
    IN: IN,
    ID: ID,
    IE: IE,
    IL: IL,
    IT: IT,
    KR: KR,
    KW: KW,
    LB: LB,
    LU: LU,
    MY: MY,
    MX: MX,
    NL: NL,
    NZ: NZ,
    NO: NO,
    PK: PK,
    PA: PA,
    PE: PE,
    PH: PH,
    PL: PL,
    PT: PT,
    QA: QA,
    RO: RO,
    RU: RU,
    SA: SA,
    CH: CH,
    SG: SG,
    SK: SK,
    SI: SI,
    ZA: ZA,
    LK: LK,
    SE: SE,
    TW: TW,
    TH: TH,
    TR: TR,
    AE: AE,
    GB: GB,
    VE: VE,
    VN: VN,
    JP: JP,
    DO: DO,
    UY: UY,
    PY: PY,
    NI: NI,
    MD: MD,
    MT: MT,
    MO: MO,
    LT: LT,
    LV: LV,
    KZ: KZ,
    EC: EC,
    EG: EG,
    EE: EE,
    HN: HN,
    JM: JM,
    AM: AM,
    BW: BW,
    BG: BG,
    JO: JO,
    KE: KE,
    MK: MK,
    MG: MG,
    ML: ML,
    MU: MU,
    NE: NE,
    SN: SN,
    TN: TN,
    UG: UG,
    DZ: DZ,
    AO: AO,
    AI: AI,
    AG: AG,
    AZ: AZ,
    BS: BS,
    BH: BH,
    BB: BB,
    BY: BY,
    BZ: BZ,
    BM: BM,
    BO: BO,
    VG: VG,
    BN: BN,
    KY: KY,
    CY: CY,
    DM: DM,
    GH: GH,
    GD: GD,
    GY: GY,
    IS: IS,
    MS: MS,
    NG: NG,
    OM: OM,
    KN: KN,
    LC: LC,
    VC: VC,
    SR: SR,
    TZ: TZ,
    TT: TT,
    TC: TC,
    UZ: UZ,
    YE: YE,
    AL: AL,
    BJ: BJ,
    BT: BT,
    BF: BF,
    KH: KH,
    CV: CV,
    TD: TD,
    CG: CG,
    FJ: FJ,
    GM: GM,
    GW: GW,
    KG: KG,
    LA: LA,
    LR: LR,
    MW: MW,
    MR: MR,
    FM: FM,
    MN: MN,
    MZ: MZ,
    NA: NA,
    NP: NP,
    PW: PW,
    PG: PG,
    ST: ST,
    SC: SC,
    SL: SL,
    SB: SB,
    SZ: SZ,
    TJ: TJ,
    TM: TM,
    UA: UA,
    ZW: ZW,
    RW: RW,
};
