import React, { useState, useEffect } from "react";
import { postRequest } from "../../../library/api/middleware.js";
import { Menu, message, Button } from "antd";

import moment from "moment";

const MessageDropdown = () => {
  const [messages, setMessages] = useState([]);

  function getData() {
    postRequest({
      operation: "list",
      endpoint: "notification",
      data: {},
      callback: (responseData) => {
        if (responseData.response.length > 0) {
          let cleanedBody = responseData.response.map((message) => {
            // Replace \\ with nothing or \ as per your requirement
            message.body = message.body.replaceAll("\\'", '"');
            return message;
          });
          //console.log("cleanedBody", cleanedBody);
          setMessages(cleanedBody);
        } else {
          setMessages([]);
        }
      },
      errorCallback: (error) => {
        console.warn(error);
        setMessages([]);
      },
    });
  }

  useEffect(() => {
    getData();

    //return () => clearInterval(intervalId);
  }, []);

  const handleSelectMessage = (record) => {
    postRequest({
      operation: "read",
      endpoint: "notification",
      data: { messageID: record.ID },
      callback: () => {
        getData();
      },
      errorCallback: () => {},
    });
  };

  const handleDelete = (record) => {
    postRequest({
      operation: "delete",
      endpoint: "notification",
      data: { messageID: record.ID },
      callback: () => {
        getData();
        message.success("Message deleted");
      },
      errorCallback: () => {
        message.warning("Error while deleting");
      },
    });
  };
  const messageMenu = (
    <Menu style={{ width: 300 }}>
      {messages.map((message) => (
        <Menu.Item key={message.ID} onClick={() => handleSelectMessage(message)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontWeight: message.messageread ? "normal" : "bold" }}>
            <div>
              <h3>{message.subject}</h3>
              <p dangerouslySetInnerHTML={{ __html: message.body.replace("\\'", '"') }}></p>
              <small>{moment(message.added).format("MMMM Do YYYY, h:mm a")}</small>
            </div>
            <Button type="text" onClick={() => handleDelete(message)}>
              X
            </Button>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return messageMenu;
};

export default MessageDropdown;
