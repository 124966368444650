import { Avatar, Segmented, Tooltip } from "antd";
import React from "react";
import { getMarketLogo, getMarketFromId } from "../../library/helpers/utils";

export default function MarketSelect({
  markets,
  selectedMarket,
  setSelectedMarket,
  showLabel = false,
}) {
  //console.log("markets", markets);

  const options = markets.map((market) => {
    //console.log("market.id", market.id);
    if (market.id == "ALL") {
      return {
        label: (
          <Tooltip placement="top" title={"All platforms"}>
            <div className="market-select__option">ALL</div>
          </Tooltip>
        ),
        value: market.id,
      };
    } else {
      return {
        label: (
          <Tooltip placement="top" title={market.label}>
            <div className="market-select__option">
              <Avatar
                className="market-select__option--image"
                src={getMarketLogo(market.id)}
              />
            </div>
          </Tooltip>
        ),
        value: market.id,
      };
    }
  });

  const getDefaultValue = () =>
    options.find((option) => option.value === selectedMarket?.id)?.value;

  const onChange = (e) => {
    //console.log("e", e);
    setSelectedMarket(getMarketFromId(e));
  };

  return (
    options?.length && (
      <div className="filters__option" style={{ marginRight: "50px" }}>
        {showLabel && <label className="select-label">Platform</label>}
        <Segmented
          className="market-select"
          options={options}
          onChange={onChange}
          defaultValue={getDefaultValue()}
        />
      </div>
    )
  );
}
