import { Tooltip, Dropdown, Button, Row, Col, Badge } from "antd";
import {
	UserOutlined,
	NotificationOutlined,
	RightOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { defaultTabs, tabs } from "../../../library/constants/nav-tabs.jsx";
import MyAppsTab from "./MyAppsTab";
import TagManagerTab from "./TagManagerTab";
import { useLocation, useNavigate } from "react-router-dom";
import { TagContext } from "../../../library/hooks/context/TagContext";
import { buildSubTagLink } from "../../../library/helpers/session-helpers";
import { checkLogin } from "../../../library/helpers/account-helper-functions";
import { Link } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import MessageDropdown from "./NotificationsMenu";
import { postRequest } from "../../../library/api/middleware.js";
import { useMediaQuery } from "react-responsive";

export default function NavTabs() {
	const location = useLocation();
	const navigate = useNavigate();
	const tagContext = useContext(TagContext);
	const [disableAppTabs, setDisableAppTabs] = useState(true);
	const [, setDisableTagsTab] = useState(true);
	const [, setTabsVisible] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	const [notificationCount, setNotificationCount] = useState(0);

	const breakpointForScroll =
		80 * tabs.flatMap((tab) => tab).filter((tab) => tab?.visible)?.length;
	const isSmallScreen = useMediaQuery({
		query: `(max-width: ${breakpointForScroll}px)`,
	});

	let counter = 0;

	function runInterval() {
		if (checkLogin()) {
			setInterval(() => {
				postRequest({
					operation: "count",
					endpoint: "notification",
					data: {},
					callback: (responseData) => {
						if (responseData.response.count > 0) {
							setNotificationCount(responseData.response?.count);
						} else {
							setNotificationCount(0);
						}
					},
					errorCallback: () => {
						setNotificationCount(0);
					},
				});
			}, 90000);
		}
	}

	useEffect(() => {
		const disabledAppRoutes = ["/marketanalysis", "/pricing"];

		setDisableAppTabs(
			!checkLogin() ||
				(!tagContext.appList.length && !tagContext.competitorAppList.length) ||
				disabledAppRoutes.includes(location?.pathname)
		);

		setDisableTagsTab(
			disabledAppRoutes.includes(location?.pathname) || !checkLogin()
		);
	}, [
		tagContext.appList,
		tagContext.competitorAppList,
		tagContext.tagList,
		location?.pathname,
	]);

	const handleTabClick = (pageName, pageFunction) => {
		const pathname = buildSubTagLink({
			pageName: pageName,
			tag_slug: tagContext.selectedTag.slug,
			app_id: tagContext.selectedApp.appId,
		});
		tagContext.setFetchedHtml("");
		tagContext.setSelectedTab({ pageFunction: pageFunction });
		navigate(pathname, {
			state: { pageFunction: pageFunction, pageName: pageName },
		});
	};

	useEffect(() => {
		setTabsVisible(true);
		//runInterval();
	}, []);

	const getTab = (group, tab, idx) => {
		counter++;

		if (!tab.visible) return <></>;

		const selected = location.state && location.state.pageName === tab.value;
		let className =
			group.length === 1
				? "groupSingle"
				: idx === 0
				? "groupFirst"
				: idx === group.length - 1
				? "groupLast"
				: "groupMid";

		if (disableAppTabs) {
			className = "groupSingle";
		}

		if (
			tab.value === "workspace_manager" ||
			(tagContext?.selectedTag && tab.value === "app_overview")
		) {
			const TabComponent =
				tab.value === "app_overview" ? MyAppsTab : TagManagerTab;

			return (
				<Tooltip placement="bottom" title={tab.title}>
					<TabComponent tab={tab} idx={counter} />
				</Tooltip>
			);
		}

		if (
			tab.grouppermission == undefined ||
			tab.grouppermission == "" ||
			(tab.grouppermission &&
				tagContext.userData.groups?.includes(tab.grouppermission))
		) {
			if (!disableAppTabs || tab.value === "world_charts") {
				return (
					<Tooltip key={counter} placement="bottom" title={tab.tooltip}>
						{tab.showIcon ? (
							<button
								className={`nav-tabs icon ${className} ${
									selected ? "selected" : ""
								}`}
								onClick={handleTabClick.bind(null, tab.value, tab?.function)}
								type="button"
								value={tab.value}
							>
								{tab.icon({ fontSize: "24px" })}
								<span className="tab-title">{tab.title}</span>
							</button>
						) : (
							<button
								className={`${className} ${selected ? "selected" : ""}`}
								onClick={handleTabClick.bind(null, tab.value, tab?.function)}
								id={tab.id}
								type="button"
								value={tab.value}
							>
								&nbsp;
							</button>
						)}
					</Tooltip>
				);
			}
		}

		return <></>;
	};

	const handleMenuVisibleChange = (visible) => {
		setMenuVisible(visible);
	};

	return (
		<>
			{checkLogin() && (
				<Row className="nav-tabs">
					<Col style={{ minWidth: "200px" }}>
						<div className="logo-parent">
							<Link className="main-logo" to="/"></Link>
						</div>
					</Col>
					<Col>
						{tagContext.validSubscription && (
							<div className="functionrow">
								<span className="non-scrollable-tabs">
									{defaultTabs.map((group, i) =>
										group
											.filter((tab) => tab.visible)
											.map((tab, idx) => (
												<React.Fragment key={idx + i}>
													{getTab(
														group.filter((tab) => tab.visible),
														tab,
														idx
													)}
												</React.Fragment>
											))
									)}
								</span>
								<span className="scrollable-tabs">
									{tabs.map((group, i) =>
										group
											.filter((tab) => tab.visible)
											.map((tab, idx) => (
												<React.Fragment key={idx + i}>
													{getTab(
														group.filter((tab) => tab.visible),
														tab,
														idx
													)}
												</React.Fragment>
											))
									)}
									{isSmallScreen && (
										<RightOutlined
											style={{
												position: "absolute",
												right: "15px",
												color: "white",
												top: "-15px",
											}}
										/>
									)}
								</span>
							</div>
						)}
					</Col>
					<Col className="nav-tabs__profile">
						<div className="floatright">
							<div className="main-login">
								<Badge count={notificationCount} size="small">
									<Dropdown
										overlay={<MessageDropdown />}
										open={menuVisible}
										destroyPopupOnHide
										onOpenChange={handleMenuVisibleChange}
									>
										<Button
											className="profile"
											onClick={(e) => e.preventDefault()}
											icon={<NotificationOutlined />}
										></Button>
									</Dropdown>
								</Badge>
								{checkLogin() && (
									<Dropdown
										overlay={<ProfileMenu />}
										placement="bottom"
										style={{ minWidth: 150 }}
									>
										<Button
											className="profile"
											icon={<UserOutlined />}
										></Button>
									</Dropdown>
								)}
							</div>
						</div>
					</Col>
				</Row>
			)}
		</>
	);
}
