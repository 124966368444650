import { Button, Modal } from "antd";
import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import FeedbackForm from "../views/Account/FeedbackAndSupport/FeedbackForm";

import styles from "./FeedbackModal.module.css";

export default function FeedbackModal() {
	const [visible, setVisible] = useState(false);
	return (
		<>
			<div className={styles.helpButtonContainer}>
				<Button
					className={styles.helpButton}
					onClick={() => setVisible(true)}
					style={{ fontSize: 14, paddingRight: 40 }}
				>
					Contact us!
				</Button>
			</div>
			<Modal
				bodyStyle={{ padding: 0 }}
				centered
				open={visible}
				footer={null}
				onCancel={() => setVisible(false)}
			>
				<FeedbackForm setVisible={setVisible} />
			</Modal>
		</>
	);
}
