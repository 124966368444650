import { Row, Col, Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function StartPage({ next }) {
  const navigate = useNavigate();

  const handleASO = () => {
    next();
  };

  const handlePerformance = () => {
    return navigate("/worldcharts");
  };

  return (
    <div className="get-started__head">
      <h1 className="header header--page">Get started</h1>
      <p className="description">
        A <strong>Quickstart</strong> setup guide to get you started.
      </p>
    </div>
  );

  return (
    <>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h2>Let&apos;s begin! Please choose your area of interest:</h2>
      </div>
      <br></br>
      <Row gutter={0} align="middle">
        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
          <Button
            size="large"
            className="card-block"
            style={{
              margin: "auto",
              height: 150,
              paddingLeft: 50,
              paddingRight: 50,
              paddingTop: 15,
              borderRadius: 10,
            }}
            onClick={handleASO}
          >
            <div style={{ fontSize: 20 }}>
              <b>App Store Optimization</b>
            </div>
            Keyword Research
            <br />
            Competitor Tracking
            <br />
            Keyword Rankings
            <br />
            In-App-Events
          </Button>
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
          <Button
            size="large"
            className="card-block"
            type="primary"
            style={{ margin: "auto", height: 150, borderRadius: 10 }}
            onClick={handlePerformance}
          >
            <div style={{ fontSize: 20 }}>
              <b>Explore</b>
            </div>
            World Charts
            <br />
            Category Rankings
            <br />
            Market Analysis
          </Button>
        </Col>
      </Row>
    </>
  );
}
