import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Button, Dropdown, Tooltip, Menu } from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import MyAppsOverlay from "./MyAppsOverlay";
import { TagContext } from "../../../library/hooks/context/TagContext";
import { useLocation, useNavigate } from "react-router-dom";
import { buildSubTagLink } from "../../../library/helpers/session-helpers";
import { isValidAppLogo } from "../../../library/helpers/app-select-helpers";
import { checkLogin } from "../../../library/helpers/account-helper-functions";
import { getMarketLogo } from "../../../library/helpers/utils";

export default function MyAppsTab({ tab, idx }) {
  const location = useLocation();
  const navigate = useNavigate();
  const tagContext = useContext(TagContext);
  const buttonRef = useRef();
  const [visible, setVisible] = useState(false);
  const [appIcon, setAppIcon] = useState("");
  const [marketIcon, setMarketIcon] = useState("");

  useEffect(() => {
    //console.log("tagContext.setSelectedApp", tagContext.selectedApp);
    const storedApp = localStorage.getItem("selectedApp") && JSON.parse(localStorage.getItem("selectedApp"));
    if (!Array.isArray(tagContext.allAppList)) {
      return;
    }
    const appIds = tagContext.appList.map((app) => app.appId);

    if (appIds?.length && appIds.includes(storedApp?.appId)) {
      tagContext.setSelectedApp(storedApp);
    } else {
      if (tagContext.appList.length) {
        tagContext.setSelectedApp(tagContext.appList[0]);
      } else {
        if (tagContext.competitorAppList.length) {
          tagContext.setSelectedApp(tagContext.competitorAppList[0]);
        } else {
          tagContext.setSelectedApp({});
        }
      }
    }
  }, [tagContext.allAppList]);

  useEffect(() => {
    const slugs = tagContext.tagList?.map((tag) => tag.slug);
    const allowedPaths = ["/", "/worldcharts", "/account", "/welcome", "/marketanalysis", "/pricing"];
    if (!checkLogin() && location?.pathname !== "/login" && location?.pathname !== "/signup" && location?.pathname !== "/pricing" && location?.pathname !== "/forgotpassword") {
      return navigate("/login");
    }
    if (!tagContext.tagListFetched) {
      return;
    }
    if (!allowedPaths.includes(location?.pathname) && (!tagContext.tagList?.length || !slugs.includes(tagContext.selectedTag.slug))) {
      return navigate("/");
    }
    if (allowedPaths.includes(location?.pathname)) {
      return;
    }
    if (!tagContext.appList.length && tagContext.tagList.length && tagContext.tagDataFetched) {
      //return navigate(`/workspace/${tagContext.selectedTag.slug}/edit`);
    }
    let newLink;
    if (location?.pathname?.includes("edit")) {
      newLink = buildSubTagLink({
        pageName: "workspace_manager",
        tag_slug: tagContext.selectedTag?.slug,
      });
    } else {
      newLink = buildSubTagLink({
        pageName: location?.state?.pageName,
        tag_slug: tagContext.selectedTag?.slug,
        app_id: tagContext.selectedApp?.appId,
      });
    }
    if (newLink) {
      navigate(newLink, {
        state: {
          pageFunction: location.state?.pageFunction,
          pageName: location.state?.pageName,
        },
      });
    }
  }, [tagContext.appList, tagContext.selectedTag?.slug, tagContext.selectedApp?.appId]);

  useEffect(() => {
    if (!tagContext?.selectedApp?.icon) {
      return setAppIcon("");
    }
    setAppIcon(tagContext?.selectedApp?.icon);
    setMarketIcon(getMarketLogo(tagContext?.selectedApp?.market));
  }, [tagContext?.selectedApp]);
  return (
    <>
      <Dropdown trigger={["click"]} open={visible} placement="bottomLeft" dropdownRender={(menu) => <MyAppsOverlay forwardRef={buttonRef} setVisible={setVisible} />}>
        <Button
          key={idx}
          ref={buttonRef}
          onClick={() => setVisible(!visible)}
          icon={
            tagContext.selectedApp?.title ? (
              <>
                <Tooltip key={idx} placement="bottom" title={tagContext?.selectedApp?.title}>
                  {isValidAppLogo(appIcon) ? (
                    <Avatar
                      src={appIcon}
                      shape="square"
                      style={{
                        borderRadius: "5px",
                        marginRight: "3px",
                        height: "35px",
                        width: "35px",
                        background: "none",
                      }}
                    />
                  ) : (
                    <Avatar
                      shape="square"
                      style={{
                        borderRadius: "5px",
                        marginRight: "3px",
                        width: "35px",
                        background: "none",
                      }}
                    >
                      {tagContext.selectedApp?.title?.substring(0, 1) || ""}
                    </Avatar>
                  )}
                </Tooltip>
                <img
                  src={marketIcon}
                  style={{
                    height: 13,
                    width: 13,
                    marginRight: 10,
                    left: 6,
                    bottom: 5,
                    position: "absolute",
                  }}
                />

                <DownOutlined
                  style={{
                    fontSize: "11px",
                    background: "none",
                    position: "relative",
                  }}
                />
              </>
            ) : (
              <>
                <Tooltip key={idx} placement="bottom" title={"Add your app"}>
                  <PlusOutlined
                    style={{
                      fontSize: "15px",
                      background: "none",
                      position: "relative",
                      paddingBottom: "10px",
                    }}
                  />
                  <p>App</p>
                </Tooltip>
              </>
            )
          }
          value={tab.value}
          id={tab.id}
        />
      </Dropdown>
    </>
  );
}
