import React from "react";

const CustomIconChart = (props) => (
  <span role="img" aria-label="chart" className="anticon" {...props}>
    <svg
      style={{ width: 27, paddingBottom: 5 }}
      fill="currentcolor"
      viewBox="0 0 594 504"
      focusable="false"
      data-icon="chart"
      aria-hidden="true"
    >
      <path
        d="M391.872 0.0100649H327.387H224.809H201.474C196.089 0.0100649 191.502 4.59718 191.502 9.98203V22.879V58.0469V110.3V173.787V243.326V313.529V379.012V434.59V474.345C191.502 480.594 191.369 486.91 191.502 493.159V493.957C191.502 499.341 196.089 503.929 201.474 503.929H265.959H368.538H391.872C397.257 503.929 401.844 499.341 401.844 493.957V481.06V445.892V393.638V330.152V260.613V190.41V124.926V69.3488V29.5936C401.844 23.3443 401.977 17.0291 401.844 10.7798V9.98203C401.844 4.79659 397.257 -0.255998 391.872 0.0100649C386.487 0.276128 381.9 4.39778 381.9 9.98203V22.879V58.0469V110.3V173.787V243.326V313.529V379.012V434.59V474.345C381.9 480.594 381.634 486.91 381.9 493.159V493.957L391.872 483.985H327.387H224.809H201.474L211.446 493.957V481.06V445.892V393.638V330.152V260.613V190.41V124.926V69.3488V29.5936C211.446 23.3443 211.712 17.0291 211.446 10.7798V9.98203L201.474 19.954H265.959H368.538H391.872C397.058 19.954 402.11 15.3669 401.844 9.98203C401.578 4.59718 397.456 0.0100649 391.872 0.0100649Z"
        fill="currentcolor"
      />
      <path
        d="M201.476 161.355H136.526H33.8163H10.1492C4.76434 161.355 0.177279 165.942 0.177279 171.327V203.37V280.352V373.826V454.331C0.177279 467.362 -0.221599 480.458 0.177279 493.422V493.954C0.177279 499.338 4.7644 503.926 10.1492 503.926H75.0992H177.809H201.476C206.861 503.926 211.448 499.338 211.448 493.954V461.91V384.928V291.454V210.949C211.448 197.919 211.846 184.822 211.448 171.859V171.327C211.448 166.141 206.86 161.155 201.476 161.355C196.091 161.621 191.504 165.743 191.504 171.327V203.37V280.352V373.826V454.331C191.504 467.295 190.772 480.525 191.504 493.422V493.954L201.476 483.982H136.526H33.8163H10.1492L20.1212 493.954V461.91V384.928V291.454V210.949C20.1212 197.985 20.8525 184.756 20.1212 171.859V171.327C16.7972 174.651 13.473 177.975 10.1492 181.299H75.0992H177.809H201.476C206.661 181.299 211.714 176.712 211.448 171.327C211.248 165.942 207.06 161.355 201.476 161.355Z"
        fill="currentcolor"
      />
      <path
        d="M583.216 286.541H518.266H415.557H391.89C386.505 286.541 381.918 291.129 381.918 296.513V363.262V469.63V494.028C381.918 499.413 386.505 504 391.89 504H456.84H559.549H583.216C588.601 504 593.188 499.413 593.188 494.028V427.28V320.912V296.513C593.188 291.261 588.601 286.275 583.216 286.541C577.831 286.808 573.244 290.929 573.244 296.513V363.262V469.63V494.028C576.568 490.704 579.892 487.38 583.216 484.056H518.266H415.557H391.89C395.214 487.38 398.538 490.705 401.862 494.028V427.28V320.912V296.513L391.89 306.485H456.84H559.549H583.216C588.402 306.485 593.454 301.898 593.188 296.513C592.989 291.062 588.8 286.541 583.216 286.541Z"
        fill="currentcolor"
      />
    </svg>
  </span>
);

export default CustomIconChart;
