import React from "react";

const CustomIconReviews = (props) => (
  <span role="img" aria-label="rankings" className="anticon" {...props}>
    <svg style={{ width: 22, paddingBottom: 5 }} fill="currentcolor" viewBox="0 0 615 570" focusable="false" data-icon="chart" aria-hidden="true">
      <path d="M193.635 570C190.55 569.995 187.59 568.783 185.386 566.624L129.008 511.444C117.451 500.06 101.871 493.691 85.647 493.72C62.9458 493.701 41.1802 484.678 25.1244 468.631C9.06867 452.583 0.0332366 430.824 0 408.123V85.623C0.0264471 62.9243 9.05697 41.1611 25.1078 25.1078C41.1611 9.05697 62.9243 0.0264662 85.623 0H528.882C551.581 0.0264471 573.344 9.05697 589.398 25.1078C605.448 41.1611 614.479 62.9243 614.505 85.623V408.123C614.481 430.824 605.451 452.59 589.4 468.64C573.347 484.694 551.584 493.722 528.882 493.748H296.719C279.511 493.775 263.081 500.932 251.34 513.514L202.283 566.234C200.112 568.564 197.093 569.92 193.907 569.992L193.635 570ZM85.6218 23.6333C69.1863 23.6477 53.426 30.1828 41.8043 41.8045C30.1819 53.4269 23.6472 69.1865 23.6331 85.622V408.122C23.6475 424.56 30.1825 440.318 41.8043 451.939C53.4266 463.564 69.1863 470.099 85.6218 470.113C108.02 470.067 129.534 478.847 145.5 494.552L193.237 541.289L234.037 497.44H234.035C250.247 480.048 272.943 470.154 296.717 470.114H528.881C545.316 470.099 561.076 463.564 572.698 451.94C584.32 440.318 590.855 424.56 590.869 408.122V85.6227C590.855 69.1872 584.32 53.4269 572.698 41.8051C561.076 30.1828 545.316 23.6481 528.881 23.6339L85.6218 23.6333Z" fill="currentcolor" />
      <path d="M291.461 357.926H56.983C52.7611 357.926 48.8616 355.673 46.7505 352.016C44.6395 348.359 44.6395 343.856 46.7505 340.199C48.8614 336.542 52.7611 334.29 56.983 334.29H291.461C295.683 334.29 299.585 336.542 301.696 340.199C303.807 343.856 303.807 348.359 301.696 352.016C299.585 355.673 295.683 357.926 291.461 357.926Z" fill="currentcolor" />
      <path d="M291.461 276.09H56.983C52.7611 276.09 48.8616 273.837 46.7505 270.18C44.6395 266.526 44.6395 262.02 46.7505 258.363C48.8614 254.706 52.7611 252.454 56.983 252.454H291.461C295.683 252.454 299.585 254.706 301.696 258.363C303.807 262.02 303.807 266.526 301.696 270.18C299.585 273.837 295.683 276.09 291.461 276.09Z" fill="currentcolor" />
      <path d="M291.461 194.254H56.983C52.7611 194.254 48.8616 192.001 46.7505 188.347C44.6395 184.69 44.6395 180.184 46.7505 176.527C48.8614 172.873 52.7611 170.62 56.983 170.62H291.461C295.683 170.62 299.585 172.873 301.696 176.527C303.807 180.184 303.807 184.69 301.696 188.347C299.585 192.001 295.683 194.254 291.461 194.254Z" fill="currentcolor" />
      <path d="M517.679 357.926C515.765 357.926 513.881 357.462 512.186 356.572L453.216 325.552L394.246 356.572C391.63 357.95 388.594 358.296 385.735 357.543C382.876 356.791 380.402 354.995 378.803 352.509C377.204 350.023 376.596 347.027 377.096 344.113L388.36 278.446L340.655 231.913C338.539 229.853 337.272 227.071 337.104 224.121C336.935 221.174 337.88 218.267 339.748 215.978C341.617 213.689 344.273 212.184 347.197 211.759L413.129 202.156L442.615 142.477C443.92 139.828 446.175 137.762 448.928 136.69C451.684 135.62 454.739 135.62 457.495 136.69C460.25 137.762 462.503 139.828 463.811 142.477L493.294 202.221L559.22 211.824C562.134 212.259 564.778 213.766 566.639 216.048C568.498 218.332 569.438 221.227 569.277 224.167C569.113 227.107 567.858 229.879 565.757 231.945L518.051 278.477L529.322 344.113C529.909 347.547 528.952 351.064 526.709 353.726C524.463 356.389 521.157 357.926 517.673 357.926L517.679 357.926ZM453.219 300.396C455.133 300.396 457.02 300.863 458.713 301.755L501.994 324.511L493.728 276.317H493.73C493.072 272.485 494.343 268.573 497.127 265.859L532.136 231.735L483.782 224.705C479.93 224.145 476.603 221.726 474.881 218.235L453.248 174.394L431.607 218.235C429.888 221.726 426.558 224.145 422.706 224.705L374.327 231.738L409.336 265.861L409.339 265.859C412.123 268.574 413.394 272.485 412.736 276.318L404.47 324.511L447.744 301.755H447.747C449.435 300.87 451.312 300.403 453.219 300.396Z" fill="currentcolor" />
    </svg>
  </span>
);

export default CustomIconReviews;
