import React from "react";
import {
  AppstoreAddOutlined,
  FieldBinaryOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  RiseOutlined,
  TagOutlined,
  UsergroupDeleteOutlined,

} from "@ant-design/icons";

import CustomIconChart from "./customicons/chartIcon";
import CustomIconRankings from "./customicons/rankingsIcon";
import CustomIconReviews from "./customicons/reviewsIcon";
export const defaultTabs = [
  [
    {
      title: "Workspace Manager",
      value: "workspace_manager",
      id: "workspaceManager",
      pageType: "workspaces",
      icon: <TagOutlined />,
      visible: true,
    },
  ],
  [
    {
      title: "App Overview",
      value: "app_overview",
      id: "functionOverview",
      pageType: "appList",
      icon: <AppstoreAddOutlined />,
      visible: true,
    },
  ],
];

export const tabs = [
  [
    {
      title: "KEYWORDS",
      tooltip: "Keyword App Store Optimization",
      value: "keyword_rankings",
      id: "functionKeywords",
      function: "getKeywordsData",
      pageType: "keywords",
      showIcon: true,
      icon: (styles) => (
        <span
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            letterSpacing: "-1px",
            paddingTop: 6,
          }}
        >
          ASO
        </span>
      ),
      visible: true,
    },
    {
      title: "Competitors",
      value: "competitors_data",
      id: "functionCompetitor",
      function: "getCompetitor",
      pageType: "competitors",
      icon: <UsergroupDeleteOutlined />,
      visible: false,
    },
  ],
  [
    {
      title: "REVIEWS",
      value: "app_reviews",
      tooltip: "App Reviews & Word Phrase Analysis",
      id: "functionReviews",
      function: "getReviews",
      pageType: "apps",
      showIcon: true,
      icon: (styles) => <CustomIconReviews style={styles} />,
      visible: true,
    },
  ],
  [
    {
      title: "CHARTS",
      value: "world_charts",
      tooltip: "World Wide App Rankings",
      id: "functionWorldCharts",
      function: "worldcharts",
      pageType: "worldcharts",
      label: "World Charts",
      showIcon: true,
      icon: (styles) => <CustomIconChart style={styles} />,
      visible: true,
    },
    {
      title: "RANKINGS",
      value: "app_rankings",
      tooltip: "Category App Rankings",
      id: "functionRanking",
      function: "getRanking",
      pageType: "appRankings",
      showIcon: true,
      icon: (styles) => <CustomIconRankings style={styles} />,
      visible: true,
    },
    {
      title: "App Info",
      value: "app_info",
      id: "functionInfo",
      function: "getInfo",
      pageType: "apps",
      icon: <InfoCircleOutlined />,
      visible: false,
    },
  ],
  [
    {
      title: "CMA Labs",
      value: "cma_labs",
      id: "functionCMALabs",
      function: "cmalabs",
      pageType: "cmalabs",
      icon: null,
      visible: true,
      grouppermission: "cma",
    },
  ],
];

export const hamburgerTabs = tabs.flatMap((tab) => tab);

export const pageTypes = [
  { value: "keywords", label: "ASO - Keywords", icon: <KeyOutlined /> },
  { value: "reviews", label: "Reviews", icon: <KeyOutlined /> },

  {
    value: "worldCharts",
    label: "World Charts",
    icon: <FieldBinaryOutlined />,
  },
  { value: "appRankings", label: "App Rankings", icon: <RiseOutlined /> },
];
