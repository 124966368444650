"use strict";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { StyleProvider } from "@ant-design/cssinjs";
import Loader from "./components/elements/Loader";
import { routes, subAppLinkPrefix, subTagLinkPrefix } from "./config/routes";
import TagsDataLoader from "./components/data-loaders/TagsDataLoader";
import * as Sentry from "@sentry/browser";
import "antd/dist/reset.css";
import "./assets/scss/styles.scss";
import "devextreme/dist/css/dx.light.css";

import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight

Sentry.init({
  dsn: "https://2f4b6149eb774f658ffd6c8bb5c28b76@glitchy.applyzer.com/2",
  environment: "development",
});
/*
Sentry.init({
    dsn: "https://5c0e453768e3452a97eb5b1d80bc81fd@glitchy.applyzer.com/2",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: "development",
  });
*/

const App = () => {
  const getPage = () => {
    return (
      <StyleProvider hashPriority="low">
        <TagsDataLoader>
          <Routes>
            {routes.map((route, i) => (
              <React.Fragment key={i}>
                {route?.component && (
                  <Route
                    key={route.name}
                    path={
                      route.sub_tag_link && route.sub_app_link
                        ? `${subTagLinkPrefix}${subAppLinkPrefix}${route.link}`
                        : route.sub_tag_link
                        ? `${subTagLinkPrefix}${route.link}`
                        : route.sub_app_link
                        ? `${subAppLinkPrefix}${route.link}`
                        : route.link ?? ""
                    }
                    element={
                      route.component.Layout ? (
                        <route.component.Layout>
                          <Suspense fallback={<Loader />}>
                            <route.component />
                          </Suspense>
                        </route.component.Layout>
                      ) : (
                        (
                          <>
                            <Suspense fallback={<Loader />}>
                              <route.component />
                            </Suspense>
                          </>
                        ) ?? <></>
                      )
                    }
                  />
                )}
              </React.Fragment>
            ))}
          </Routes>
        </TagsDataLoader>
      </StyleProvider>
    );
  };

  return getPage();
};

export default App;

/*

{

                                route?.sub_routes &&
                                route.sub_routes.map((subroute, i) => (
                                    <React.Fragment key={i}>
                                        {subroute?.component && (
                                            <Route
                                                key={subroute.name}
                                                path={
                                                    subroute.sub_project_link
                                                        ? `${subProjectLinkPrefix}${subroute.link}`
                                                        : subroute.link ?? ''
                                                }
                                                element={
                                                    subroute.component.Layout ? (
                                                        <subroute.component.Layout>
                                                            <subroute.component />
                                                        </subroute.component.Layout>
                                                    ) : (
                                                        <subroute.component /> ?? <></>
                                                    )
                                                }
                                            />
                                        )}
                                    </React.Fragment>
                                ))}

*/
