import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";

import { submitFeedback } from "../../../../library/helpers/account-helper-functions";

export default function FeedbackForm({ setVisible }) {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
    },
    layout: "vertical",
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const onFinish = (values) => {
    submitFeedback({
      email: values.email,
      feedback: values.feedback,
      onSuccess: () => {
        message.success("Feedback submitted successfully");
        setVisible(false);
      },
      onError: () => {
        message.error(
          "Unable to submit feedback, please try again or email feedback@applyzer.com if the issue persists"
        );
      },
    });
  };

  const stopSubmitting = () => {
    setSubmitting(false);
  };
  return (
    <div className="account-form">
      <Form
        {...formItemLayout}
        form={form}
        name="feedback_form"
        onFinish={onFinish}
        scrollToFirstError
      >
        {/* {Cookies.get('login_jwt') && (
                    <Form.Item name='app' label='App'>
                        <Select defaultValue={'noApp'}>
                            <Option value='noApp'>No App Problem</Option>
                            {tagContext.appList.map((app, idx) => (
                                <Option key={idx} value={app.appId}>
                                    {app.title}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )} */}
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Please enter a valid email address",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item
          name="feedback"
          label="Feedback"
          rules={[
            {
              required: true,
              message: "Please enter your feedback",
            },
          ]}
          hasFeedback
        >
          <Input.TextArea
            style={{
              height: 180,
            }}
          />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            type="primary"
            className="add-button login-form-button"
            htmlType="submit"
          >
            Submit to APPlyzer
          </Button>
        </Form.Item>
      </Form>
      <div className="explanation">
        Please write your feedback in English or German.
      </div>
      <div className="explanation">
        If you have any problem with an app or function, please mention the app
        name or appID, and in which function the error occurred.
      </div>
      <div className="explanation">
        Your feedback will be answered within 24 hours.
      </div>
    </div>
  );
}
