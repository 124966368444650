import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Avatar, Button, Menu, Row, Col, Tooltip } from "antd";
import {
	hamburgerTabs,
	pageTypes,
	defaultTabs,
	tabs,
} from "../../../library/constants/nav-tabs";
import { Link } from "react-router-dom";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	TagOutlined,
} from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";
import TagsOverlay from "../Tabs/TagsOverlay";
import MyAppsOverlay from "../Tabs/MyAppsOverlay";
import { TagContext } from "../../../library/hooks/context/TagContext";
import TagManagerTab from "../Tabs/TagManagerTab";
import MyAppsTab from "../Tabs/MyAppsTab";
import { useWindowDimensions } from "../../../library/helpers/utils";
import { buildSubTagLink } from "../../../library/helpers/session-helpers";
import { useNavigate } from "react-router-dom";

export default function NavHamburgerMenu() {
	const navigate = useNavigate();
	const tagContext = useContext(TagContext);
	const [open, setOpen] = useState(false);
	const [visibility, setVisibility] = useState("hidden");
	const [disableAppTabs, setDisableAppTabs] = useState(true);
	const [, setDisableTagsTab] = useState(true);
	const [, setTabsVisible] = useState(false);
	const { width } = useWindowDimensions();

	let counter = 0;
	const breakpointForScroll =
		80 * tabs.flatMap((tab) => tab).filter((tab) => tab?.visible)?.length;
	const isSmallScreen = useMediaQuery({
		query: `(max-width: ${breakpointForScroll}px)`,
	});

	useEffect(() => {
		if (!open) {
			setVisibility("hidden");
		} else {
			setVisibility("visible");
		}
	}, [open]);

	const getItem = (label, key, icon, pageName, pageFunction) => {
		return (
			<Menu.Item
				key={key}
				pagename={pageName}
				pagefunction={pageFunction}
				icon={icon}
			>
				{label}
			</Menu.Item>
		);
	};

	const getSubMenu = (label, key, icon, items) => {
		return (
			<Menu.SubMenu key={key} icon={icon} title={label}>
				{items}
			</Menu.SubMenu>
		);
	};

	const getTab = (group, tab, idx) => {
		counter++;

		if (!tab.visible) return <></>;

		const selected = location.state && location.state.pageName === tab.value;
		let className =
			group.length === 1
				? "groupSingle"
				: idx === 0
				? "groupFirst"
				: idx === group.length - 1
				? "groupLast"
				: "groupMid";

		if (disableAppTabs) {
			className = "groupSingle";
		}

		if (
			tab.value === "workspace_manager" ||
			(tagContext?.selectedTag && tab.value === "app_overview")
		) {
			const TabComponent =
				tab.value === "app_overview" ? MyAppsTab : TagManagerTab;

			return (
				<Tooltip placement="bottom" title={tab.title}>
					<TabComponent tab={tab} idx={counter} />
				</Tooltip>
			);
		}

		if (
			tab.grouppermission == undefined ||
			tab.grouppermission == "" ||
			(tab.grouppermission &&
				tagContext.userData.groups?.includes(tab.grouppermission))
		) {
		}

		return <></>;
	};

	const getAllMenuItems = () => {
		return pageTypes.map(({ value, label, icon }, idx) => {
			if (value === "worldCharts") {
				return getItem(label, idx, icon, "world_charts", "worldcharts");
			}
			if (value === "keywords") {
				return getItem(label, idx, icon, "keyword_rankings", "keywords");
			}
			if (value === "reviews") {
				return getItem(label, idx, icon, "app_reviews", "reviews");
			}
			if (value === "appRankings") {
				return getItem(label, idx, icon, "app_rankings", "appRankings");
			}

			const items = hamburgerTabs.reduce(
				(
					acc,
					{
						pageType,
						title,
						icon: tabIcon,
						value: tabValue,
						function: tabFunction,
						hide,
					},
					i
				) => {
					if (pageType === value && !hide) {
						acc = [
							...acc,
							getItem(title, `${idx}.${i}`, tabIcon, tabValue, tabFunction),
						];
					}
					return acc;
				},
				[]
			);
			return getSubMenu(label, idx, icon, items);
		});
	};

	const onClick = ({ item }) => {
		const pageName = item.props.pagename;
		const pageFunction = item.props.pagefunction;
		const pathname = buildSubTagLink({
			pageName: pageName,
			tag_slug: tagContext.selectedTag.slug,
			app_id: tagContext.selectedApp.appId,
		});
		tagContext.setFetchedHtml("");
		tagContext.setSelectedTab({ pageFunction: pageFunction });
		navigate(pathname, {
			state: { pageFunction: pageFunction, pageName: pageName },
		});
		toggleOpen();
	};

	const toggleOpen = () => {
		setOpen(!open);
		setVisibility(visibility == "hidden" ? "visible" : "hidden");
	};

	return (
		<>
			<Row className="nav-tabs">
				<Col>
					{tagContext.validSubscription && (
						<>
							<div className="functionrow">
								<span className="non-scrollable-tabs">
									{defaultTabs.map((group, i) =>
										group
											.filter((tab) => tab.visible)
											.map((tab, idx) => (
												<React.Fragment key={idx + i}>
													{getTab(
														group.filter((tab) => tab.visible),
														tab,
														idx
													)}
												</React.Fragment>
											))
									)}
									<Button
										type="text"
										onClick={toggleOpen}
										style={{
											height: 45,
											color: "white",
											backgroundColor: "transparent",
										}}
									>
										{!open ? (
											<MenuUnfoldOutlined style={{ fontSize: "26px" }} />
										) : (
											<MenuFoldOutlined style={{ fontSize: "26px" }} />
										)}
									</Button>
								</span>

								<div className="hamburger">
									<Menu
										mode="inline"
										theme="light"
										inlineCollapsed={!open}
										style={{
											visibility: visibility,
											position: "relative",
											zIndex: 99,

											left: 0,
											background:
												"linear-gradient(to bottom, #ebeff1 0%, #fff 100%)",
											boxShadow:
												"rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
											height: "calc(100vh - 33px)",
										}}
										onClick={onClick}
									>
										{getAllMenuItems()}
									</Menu>
								</div>
							</div>
						</>
					)}
				</Col>
			</Row>
		</>
	);
}
