import React from "react";
import NavTabs from "./Tabs/NavTabs";
import { useWindowDimensions } from "../../library/helpers/utils";
import NavHamburgerMenu from "./NavHamburger/NavHamburgerMenu";

export default function NavBar() {
  const { width } = useWindowDimensions();
  return (
    <div id="content">{width < 768 ? <NavHamburgerMenu /> : <NavTabs />}</div>
  );
}
