import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "@babel/runtime/helpers/esm/objectSpread2";
/**
 * respect https://github.com/cuth/postcss-pxtorem
 */
import unitless from '@emotion/unitless';
var pxRegex = /url\([^)]+\)|var\([^)]+\)|(\d*\.?\d+)px/g;
function toFixed(number, precision) {
  var multiplier = Math.pow(10, precision + 1),
    wholeNumber = Math.floor(number * multiplier);
  return Math.round(wholeNumber / 10) * 10 / multiplier;
}
var transform = function transform() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _options$rootValue = options.rootValue,
    rootValue = _options$rootValue === void 0 ? 16 : _options$rootValue,
    _options$precision = options.precision,
    precision = _options$precision === void 0 ? 5 : _options$precision,
    _options$mediaQuery = options.mediaQuery,
    mediaQuery = _options$mediaQuery === void 0 ? false : _options$mediaQuery;
  var pxReplace = function pxReplace(m, $1) {
    if (!$1) return m;
    var pixels = parseFloat($1);
    // covenant: pixels <= 1, not transform to rem @zombieJ
    if (pixels <= 1) return m;
    var fixedVal = toFixed(pixels / rootValue, precision);
    return "".concat(fixedVal, "rem");
  };
  var visit = function visit(cssObj) {
    var clone = _objectSpread({}, cssObj);
    Object.entries(cssObj).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      if (typeof value === 'string' && value.includes('px')) {
        var newValue = value.replace(pxRegex, pxReplace);
        clone[key] = newValue;
      }

      // no unit
      if (!unitless[key] && typeof value === 'number' && value !== 0) {
        clone[key] = "".concat(value, "px").replace(pxRegex, pxReplace);
      }

      // Media queries
      var mergedKey = key.trim();
      if (mergedKey.startsWith('@') && mergedKey.includes('px') && mediaQuery) {
        var newKey = key.replace(pxRegex, pxReplace);
        clone[newKey] = clone[key];
        delete clone[key];
      }
    });
    return clone;
  };
  return {
    visit: visit
  };
};
export default transform;