import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Button, Input, Popconfirm, Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	deleteTag,
	editTag,
} from "../../../library/helpers/tag-helper-functions";
import { TagContext } from "../../../library/hooks/context/TagContext";

export default function EditTag() {
	const navigate = useNavigate();
	const tagContext = useContext(TagContext);
	const [tagText, setTagText] = useState(tagContext.selectedTag.Name);
	const [showEdit, setShowEdit] = useState(true);

	const handleTextChange = (e, f) => {
		setTagText(e.target.value);
	};

	const handleEditTag = () => {
		if (tagText === tagContext.selectedTag.Name) {
			setShowEdit(false);
			return;
		}
		editTag({
			tagSlug: tagContext.selectedTag.slug,
			newTagName: tagText,
			onSuccess: (response) => {
				tagContext.setTagList(response);
				const newTag = response.find((tag) => tag.Name === tagText);
				tagContext.setSelectedTag(newTag);
				setShowEdit(false);
			},
		});
	};

	const handleShowEdit = () => {
		setShowEdit(true);
	};

	const handleDeleteTag = () => {
		deleteTag({
			tagSlug: tagContext.selectedTag.slug,
			onSuccess: () => {
				navigate("/");

				location.reload();
				//return;
				/*
        const newList = tagContext.tagList.filter(
					(tag) => tag.slug !== tagSlug
				);
				tagContext.setTagList(newList);
				if (newList.length) {
					tagContext.setSelectedTag(newList);
				} else {
					navigate("/");
				}
        */
			},
		});
	};

	const cancel = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	useEffect(() => {
		setTagText(tagContext.selectedTag.Name);
	}, [tagContext.selectedTag.Name]);

	return (
		<div className="box-shadow" style={{ textAlign: "center" }}>
			{" "}
			<h3 style={{ fontSize: 14 }}>
				Changing the name of a workspace doesn't effect the workspace name shown
				in the URL.
			</h3>
			<div className="">
				{showEdit ? (
					<Input.Group compact>
						<Input
							style={{
								width: "250px",
							}}
							className="search-bar"
							onChange={handleTextChange}
							value={tagText}
						/>
						<Button
							className="add-button"
							type="primary"
							onClick={handleEditTag}
						>
							Save Workspace name
						</Button>
					</Input.Group>
				) : (
					<div className="edit-tag-controls__header">
						<h2 className="header header--main">
							{tagContext.selectedTag.Name}
						</h2>{" "}
						<Tooltip title="Edit Workspace">
							<Button
								size="small"
								className="add-button add-button--inverse"
								onClick={handleShowEdit}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
					</div>
				)}
				<Divider></Divider>
				<Popconfirm
					title="Are you sure you want to delete this workspace?"
					onConfirm={handleDeleteTag}
					onCancel={cancel}
					okText="Yes"
					cancelText="No"
					okButtonProps={{ className: "pop-confirm-btn" }}
					cancelButtonProps={{ className: "pop-confirm-btn" }}
				>
					<Button className="delete-button" danger>
						Delete Workspace
					</Button>
				</Popconfirm>
			</div>
		</div>
	);
}
