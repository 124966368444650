import React from 'react';
import { endpoints } from '../api/endpoints';
import { postRequest } from '../api/middleware';
import { generalOperations, tagsOperations } from '../api/operations';

export const deleteApp = ({ tagSlug, id, appType, onSuccess, apps }) => {
    postRequest({
        operation: tagsOperations.deleteApp,
        endpoint: endpoints.tags,
        data: {
            tagslug: tagSlug,
            apps: [id],
            apptype: appType,
        },
        callback: () => {
            onSuccess()
        },
        errorCallback: (error) => console.warn(error),
    });
};

export const searchAppsByText = ({ value, market, onSuccess }) => {

    postRequest({
        operation: generalOperations.searchAppsByText,
        endpoint: endpoints.general,
        data: {
            term: value,
            market: market
        },
        callback: (response) => onSuccess(response?.response || []),
        errorCallback: (error) => console.warn(error),
    });
};


export const addAppsToTag = ({ tagSlug, apps, appType, onSuccess }) => {
    postRequest({
        operation: tagsOperations.addApp,
        endpoint: endpoints.tags,
        data: {
            tagslug: tagSlug,
            apps: apps,
            apptype: appType,
        },
        callback: (response) => {
            onSuccess(response);
        },
        errorCallback: (error) => console.warn(error),
    });
};

export const addAppToTag = ({ tagSlug, value, appType, onSuccess }) => {
    postRequest({
        operation: tagsOperations.addApp,
        endpoint: endpoints.tags,
        data: {
            tagslug: tagSlug,
            apps: [value.appid],
            apptype: appType,
        },
        callback: (response) => {
            onSuccess(response);
        },
        errorCallback: (error) => console.warn(error),
    });
};



export const getAppData = (appIds, allAppData) => {
    let appData = [];
    for (const id in allAppData) {
        if (appIds?.includes(id)) {
            if (allAppData[id] !== undefined) {
                appData.push({
                    appId: id,
                    ...allAppData[id],
                });
            } else {
                appData.push({
                    appId: id,
                    title: id,
                    icon: null
                });

            }
        }
    }
    return appData;
};

export const isValidAppLogo = (url) => {
    if (!url) {
        return false;
    }
    //return url.substring(0, 5) === 'https';
    return true;
};

export const renderAppTooltip = (title, id) => {
    return (
        <>
            <h4 className='tooltip__title'>{title}</h4>
            <p className='tooltip__body'>(id: {id})</p>
        </>
    );
};

export const getAppObjectFromId = (id, list) => {
    return list.find((app) => app.appId === id);
};
