import { routes, subAppLinkPrefix, subTagLinkPrefix } from '../../config/routes';
import { tabs } from '../constants/nav-tabs';

export const getRouteItem = (items, pageName) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].name === pageName) {
      return items[i];
    }
    if (typeof items[i].sub_routes !== 'undefined') {
      let sub = getRouteItem(items[i].sub_routes, pageName);
      if (typeof sub === 'object') {
        return sub;
      }
    }
  }
};

export const getRouteItemByLink = (routes, link, params = {}) => {
  let routeLink = link;
  for (const param in params) {
    if (param === 'tag_slug' && params[param]) {
      routeLink = routeLink.replace(`/workspace/${params[param]}`, '');
    } else if (param === 'app_id' && params[param]) {
      routeLink = routeLink.replace(`/app/${params[param]}`, '');
    } else if (params[param]) {
      routeLink = routeLink.replace(params[param], `:${param}`);
    }
  }

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].link === routeLink) {
      return routes[i];
    }
    if (routes[i]?.sub_routes) {
      let sub = getRouteItemByLink(routes[i].sub_routes, routeLink);
      if (typeof sub === 'object') {
        return sub;
      }
    }
  }
};

export const buildSubTagLink = ({ pageName, tag_slug = '', app_id = '', params = {} }) => {
  const getRoute = getRouteItem(routes, pageName);
  if (getRoute?.link) {
    let pathname = getRoute?.link;
    if (getRoute.sub_app_link) {
      pathname = `${subAppLinkPrefix}${pathname}`;
      pathname = pathname.replace(':app_id', app_id);
    }
    if (getRoute.sub_tag_link) {
      pathname = `${subTagLinkPrefix}${pathname}`;
      pathname = pathname.replace(':tag_slug', tag_slug);
    }
    for (const param in params) {
      pathname = `${pathname}`.replace(`:${param}`, params[param]);
    }
    return pathname;
  }
};

export const isValidPageParams = ({ pathName, params = {}, tagList, allAppList }) => {
  const { tag_slug, app_id } = params;
  const tagSlugs = tagList?.map((tag) => tag.slug);
  const appIds = allAppList?.map((app) => app.appId);
  const route = getRouteItemByLink(routes, pathName, params);

  if (!route?.name) {
    return false;
  }
  if (!route?.sub_tag_link && !route?.sub_app_link) {
    return true;
  }
  if (
    (route.sub_tag_link && !tagSlugs.includes(tag_slug)) ||
    (route.sub_app_link && !appIds.includes(app_id))
  ) {
    return false;
  }
  return true;
};

export const getPageFunctionFromPathname = ({ pathname, params = {} }) => {
  let route = getRouteItemByLink(routes, pathname, params);
  let pageName = route?.name;
  const tab = tabs.find((tab) => tab.value === pageName);
  return tab.function;
};
