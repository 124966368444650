export const endpoints = {
    tags: '/tag',
    general: '/general',
    login: '/login',
    logout: '/logout',
    user: '/user',
    checkToken: '/check',
    subscription: 'subscription',
    keyword: 'keyword',
    competitors: 'competitors',
    rankings: 'rankings'
};
