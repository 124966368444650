import React, {
	useContext,
	useEffect,
	useRef,
	useState,
	forwardRef,
} from "react";
import { Button, Dropdown, Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";
import TagsOverlay from "./TagsOverlay";
import { TagContext } from "../../../library/hooks/context/TagContext";

export default function TagManagerTab({ tab, idx, addStyle }) {
	const tagContext = useContext(TagContext);
	const [visible, setVisible] = useState(false);
	const [customStyle, setCustomStyle] = useState({});
	const dropdownRef = useRef();
	const overlayRef = useRef();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target) &&
				overlayRef.current &&
				!overlayRef.current?.contains(event.target)
			) {
				setVisible(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	useEffect(() => {
		addStyle && setCustomStyle({ position: "relative", marginTop: "10px" });
	}, [addStyle]);

	const onOverlayClick = (event) => {
		event.stopPropagation();
	};

	return (
		<Tooltip title={"Workspace - " + tagContext?.selectedTag?.Name}>
			<Dropdown
				trigger={["click"]}
				open={visible}
				onOpenChange={(v) => setVisible(v)}
				overlay={
					<TagsOverlay
						forwardedRef={overlayRef}
						setVisible={setVisible}
						dropdownVisible={visible}
					/>
				}
			>
				<Button
					ref={dropdownRef}
					className="dropdown-button"
					type="text"
					key={idx}
					value={tab.value}
					id={tab.id}
					onClick={() => setVisible(!visible)}
					style={customStyle}
				>
					{tagContext?.selectedTag?.Name
						? tagContext?.selectedTag?.Name
						: "Add"}{" "}
					{
						<DownOutlined
							style={{
								fontSize: "11px",
								background: "none",
								position: "relative",
							}}
						/>
					}
				</Button>
			</Dropdown>
		</Tooltip>
	);
}
