import _axios from "axios";
import { sprintf } from "sprintf-js";
import Cookies from "js-cookie";
import forge from "node-forge";

export const userRequestClient = _axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const buildEndpoint = (endpoint, args = null, operation = null) => {
  const endpointArgs = args === null ? endpoint : sprintf(endpoint, args);
  return operation === null ? endpointArgs : `${endpointArgs}/${operation}`;
};

// Encrypt data using AES
function encryptAES(key, data) {
  const iv = forge.random.getBytesSync(16);
  const cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(data));
  cipher.finish();
  const encrypted = cipher.output;
  return { iv: iv, encrypted: encrypted };
}

// Encrypt the AES key using RSA public key
function encryptWithPublicKey(publicKeyPem, data) {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  return publicKey.encrypt(data, "RSA-OAEP");
}

// Main function to encrypt data
function encryptData(data, publicKeyPem) {
  // Convert data to string
  const dataString = JSON.stringify(data);

  // Generate a random AES key
  const aesKey = forge.random.getBytesSync(32); // 256-bit key

  // Encrypt the data using AES
  const { iv, encrypted } = encryptAES(aesKey, dataString);

  // Encrypt the AES key using the RSA public key
  const encryptedAESKey = encryptWithPublicKey(publicKeyPem, aesKey);

  return {
    encryptedData: forge.util.encode64(encrypted.getBytes()),
    iv: forge.util.encode64(iv),
    encryptedKey: forge.util.encode64(encryptedAESKey),
  };
}

export const postRequest = ({
  operation = null,
  endpoint,
  args = null,
  data,
  callback,
  errorCallback = false,
  logging = false,
}) => {
  let doencryption = false;

  if (doencryption) {
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;
    const formattedPublicKey = publicKey.split("\\n").join("\n");
    let r = (Math.random() + 1).toString(4).substring(7);
    data.random = r;

    let dataenc = encryptData(data, formattedPublicKey);
    //"Content-Type": "text/plain",
    userRequestClient
      .post(buildEndpoint(endpoint, args, operation), dataenc, {
        headers: {
          Authorization: `Bearer ${Cookies.get("login_jwt")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (errorCallback) {
          userRequestClient
            .post(buildEndpoint(endpoint, args, operation), data, {
              headers: {
                Authorization: `Bearer ${Cookies.get("login_jwt")}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "text/plain",
              },
            })
            .then((response) => {
              callback(response.data);
            })
            .catch((error) => {
              if (errorCallback) {
                errorCallback(error);
              }
              if (logging) {
                console.error(error);
              }
            });
        }
        if (logging) {
          console.error(error);
        }
      });
  } else {
    //console.log("data", data);
    userRequestClient
      .post(buildEndpoint(endpoint, args, operation), data, {
        headers: {
          Authorization: `Bearer ${Cookies.get("login_jwt")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (errorCallback) {
          errorCallback(error);
        }
        if (logging) {
          console.error(error);
        }
      });
  }
};

export const postRequestDownload = ({
  operation = null,
  endpoint,
  args = null,
  data,
  callback,
  errorCallback = false,
  logging = false,
}) => {
  userRequestClient
    .post(buildEndpoint(endpoint, args, operation), data, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${Cookies.get("login_jwt")}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Get filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = "unknown";
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      callback(response);
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
      if (logging) {
        console.error(error);
      }
    });
};

export const fileUploadRequest = ({
  operation = null,
  endpoint,
  args = null,
  data,
  callback,
  errorCallback = false,
}) => {
  userRequestClient
    .post(buildEndpoint(endpoint, args, operation), data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Cookies.get("login_jwt")}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
      console.error(error);
    });
};
