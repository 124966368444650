.helpButton {
    color: #fff !important;
    width: 35px;
    height: 33px;
    background-color: #F27405 !important;
    border: none;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
    font-size: 1.2rem;
    display: flex;
}

.helpButtonContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: 2%;
    position: fixed;
    bottom: 10px;
    right: -5px;
    z-index: 100;
}