import React from "react";

const CustomIconRankings = (props) => (
  <span role="img" aria-label="rankings" className="anticon" {...props}>
    <svg
      style={{ width: 21, paddingBottom: 6 }}
      fill="none"
      viewBox="0 0 508 527"
      focusable="false"
      data-icon="chart"
      aria-hidden="true"
    >
      <path
        d="M12 513.967V359.688C12 359.41 12.1314 359.148 12.3545 358.981L117.244 280.751C117.567 280.511 118.011 280.519 118.325 280.772L256.937 392.711C257.276 392.985 257.764 392.97 258.086 392.676L492.76 178.104C493.326 177.587 494.237 177.988 494.237 178.755V513.967C494.237 514.454 493.842 514.849 493.355 514.849H12.8816C12.3947 514.849 12 514.454 12 513.967Z"
        stroke="currentcolor"
        strokeWidth={24}
        strokeLinecap="round"
      />
      <path
        d="M12 227.887L112.375 134.087C112.7 133.783 113.2 133.769 113.541 134.054L249.868 247.659C250.213 247.947 250.719 247.929 251.043 247.618L496 12.7759"
        stroke="currentcolor"
        strokeWidth={24}
        strokeLinecap="round"
      />
      <path
        d="M395.497 12.7759H494.237C495.21 12.7759 496 13.5653 496 14.5391V106.226"
        stroke="currentcolor"
        strokeWidth={24}
        strokeLinecap="round"
      />
    </svg>
  </span>
);

export default CustomIconRankings;
