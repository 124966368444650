import iosLogo from "../../assets/images/storeLogos/iosstoreicon.png";
import macLogo from "../../assets/images/storeLogos/macOs.png";
import playLogo from "../../assets/images/storeLogos/playstoreicon.png";
import { useEffect, useState } from "react";

export function findInDict(dict, search) {
	const matchName = dict.find((element) => element.id === search);

	return matchName;
}

export const getMarketFromId = (value) => {
	switch (value) {
		case "IOS":
			return {
				id: value,
				label: "iOS",
			};
		case "PLAY":
			return {
				id: value,
				label: "Google Play",
			};
		case "MAC":
			return {
				id: value,
				label: "MacOS",
			};
		case "ALL":
			return {
				id: value,
				label: "All",
			};
		default:
			break;
	}
};

export const getMarketLogo = (store) => {
	switch (store) {
		case "IOS":
			return iosLogo;
		case "MAC":
			return macLogo;
		case "PLAY":
			return playLogo;
		default:
			break;
	}
};

//GET WIDTH AND HEIGHT OF CURRENT WINDOW
export const useWindowDimensions = () => {
	const getWindowDimensions = () => {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	};
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
};

export const convertStringToNum = (str) => {
	let regex = /[k]/gim;
	if (str.search(regex) !== -1) {
		let replaceStr = str.replace(regex, "");
		return parseFloat(replaceStr) * 1000;
	}
	return parseInt(str);
};

//Capitalises first letter of every word in a string
export const capitaliseFirstLetters = (str) => {
	let arr = str.split(" ");
	let finalArr = [];
	arr.forEach((word) => {
		finalArr.push(`${word[0].toUpperCase()}${word.substring(1)}`);
	});
	return finalArr.join(" ");
};

//CUSTOMISE LINE GRAPH TOOLTIP WITH DATES ON X-AXIS
export const customiseDateTooltip = (input) => {
	let pointInfo = input;
	let currency;
	if (!input?.points) {
		input.points = [input];
	}
	if (!!input?.point?.tag) {
		currency = getCurrencySymbolByCode(input?.point?.tag);
	}
	const date = new Date(pointInfo.argumentText).toDateString();
	let time = new Date(pointInfo.argumentText).toLocaleTimeString();
	time = time.substring(0, time.length - 3);

	let allArgs = [];

	let points = [];

	pointInfo?.points.forEach((point) => {
		let value = parseFloat(point?.valueText)?.toFixed(2);
		value = value.toString();
		if (value.substring(value.length, value.length - 2) === "00") {
			value = parseInt(value);
		} else {
			value = parseFloat(value);
		}
		if (allArgs.indexOf(point?.seriesName) === -1) {
			let seriesText = point?.seriesName;
			if (
				point?.seriesName.toLowerCase() === "spend" ||
				point?.seriesName.toLowerCase() === "cpi"
			) {
				value = `${currency}${value}`;
			}
			allArgs.push(seriesText);
			points.push({
				colourHex: point.point.getColor(),
				argument: seriesText,
				value: value,
			});
		}
	});

	const tableRows = points.map(
		(point) =>
			`<tr key=${point.argument}><td style="color:${point.colourHex};" "font-family:proxima-nova";>${point.argument}</td> <td style="margin-left:2%; text-align:right">${point.value}</td></tr>`
	);

	const htmlReturn = `<h4 class="modalChartTooltipHeader">${date} (${time})</h4>
    <table style="margin-right: 0; width:100%">
        ${tableRows.join("")}
    </table>`;

	return {
		html: htmlReturn,
	};
};
