import { endpoints } from '../api/endpoints';
import { postRequest } from '../api/middleware';
import Cookies from 'js-cookie';
import { generalOperations, subscriptionOperations, userOperations } from '../api/operations';
import jwt_decode from 'jwt-decode';

export const login = ({ email, password, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.login,
    data: {
      email,
      password,
    },
    callback: (response) => {
      if (response?.message !== 'Login succeeded!') {
        onError('Login Unsuccessfull');
      } else {
        Cookies.set('login_jwt', response.access_token, {
          sameSite: 'none',
          secure: true,
        });
        onSuccess(response.message);
      }
    },
    errorCallback: (error) => onError(error),
  });
};

export const logout = ({ tagContext, onSuccess }) => {
  postRequest({
    endpoint: endpoints.logout,
    data: {},
    callback: () => {
      Cookies.remove('login_jwt', { path: '/', sameSite: 'none', secure: 'true' });
      tagContext.setTagList([]);
      tagContext.setAppList([]);
      tagContext.setSelectedTag({});
      tagContext.setSelectedApp({});
      tagContext.setSelectedCompetitorApp({});
      tagContext.setContextValue('tagListFetched', false);
      tagContext.setContextValue('tagDataFetched', false);
      tagContext.setContextValue('marketListFetched', false);
      tagContext.setContextValue('generalConfigsFetched', false);
      tagContext.setContextValue('countryListFetched', false);
      tagContext.setContextValue('validSubscription', false);
      tagContext.setContextValue('loggedin', false);
      onSuccess();
    },
  });
};

export const createUser = ({ details, onSuccess }) => {
  postRequest({
    endpoint: endpoints.user,
    operation: userOperations.create,
    data: details,
    callback: () => {
      onSuccess();
    },
    errorCallback: (error) => {
      console.warn(error);
    },
  });
};

export const verifyUser = ({ verificationCode, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.user,
    operation: userOperations.verify,
    data: {
      activatekey: verificationCode,
    },
    callback: (response) => {
      Cookies.set('login_jwt', response.access_token, {
        sameSite: 'none',
        secure: true,
      });
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError();
      console.warn(error);
    },
  });
};

export const verifySlack = ({ hash, code, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.tags,
    operation: 'notification/slackverification',
    data: {
      hash: hash,
      code: code,
    },
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError();
      console.warn(error);
    },
  });
};

export const deleteUser = ({ onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.user,
    operation: userOperations.delete,
    data: {},
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const authenticateUser = ({ onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.checkToken,
    data: {},
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const changeUserPassword = ({ currentPassword, newPassword, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.user,
    operation: userOperations.changePassword,
    data: {
      currentpassword: currentPassword,
      password: newPassword,
    },
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const userForgotPassword = ({ email, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.user,
    operation: userOperations.forgotPassword,
    data: {
      email: email,
    },
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const resetUserPassword = ({ verificationCode, password, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.user,
    operation: userOperations.resetPassword,
    data: {
      resetkey: verificationCode,
      password: password,
    },
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const getSubscriptionPlans = ({ onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.subscription,
    operation: subscriptionOperations.allPlans,
    data: {},
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const getUserSubscription = ({ onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.subscription,
    operation: subscriptionOperations.activeSubscription,
    data: {},
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const submitFeedback = ({ email, feedback, onSuccess, onError }) => {
  postRequest({
    endpoint: endpoints.general,
    operation: generalOperations.feedback,
    data: {
      email: email,
      body: feedback,
    },
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const checkLogin = () => {
  if (!Cookies.get('login_jwt')) {
    return false;
  } else {
    const token = Cookies.get('login_jwt');

    var decodedToken = jwt_decode(token);

    var dateNow = new Date();

    if (decodedToken.exp * 1000 < dateNow.getTime()) {
      return false;
    }
    return true;
  }
};
