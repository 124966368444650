import { createContext } from "react";

export const TagContext = createContext({
  //notifications
  notificationsapi: null,

  //modal
  modalopen: false,

  //Selected Data Variables
  selectedTag: {},
  selectedApp: {},
  selectedTab: {},
  previousSelectedApp: "",
  selectedCompetitorApp: {},
  selectedCountry: {},
  selectedMarket: {},
  selectedDevice: {},
  selectedDataPeriod: {},
  selectedDataType: {},
  selectedRankingType: {},
  userSubscriptions: [],
  userStats: {},
  userData: {},
  validSubscription: false,

  //Grouped Variables
  tagList: [],
  appList: [],
  competitorAppList: [],
  allAppList: null,
  countryList: [],
  marketList: [],
  deviceList: [],
  dataPeriods: [],
  dataTypes: [],
  rankingTypes: [],
  tagKeywordData: [],
  allTagsAppList: {},

  //Actions and Conditionals
  generalConfigsFetched: false,
  tagListFetched: false,
  tagDataFetched: false,
  marketListFetched: false,
  countryListFetched: false,
  subscriptionFetched: false,
  triggerAction: {},
  loggedin: false,

  //Raw Html from old repo
  fetchedHtml: "",

  //Functions
  setModalopen: () => {},
  openNotification: () => {},
  setContextValue: () => {},
  setSelectedTag: () => {},
  setSelectedApp: () => {},
  setPreviousSelectedApp: () => {},
  setSelectedCompetitorApp: () => {},
  setSelectedCountry: () => {},
  setSelectedMarket: () => {},
  setSelectedDevice: () => {},
  setSelectedDataPeriod: () => {},
  setSelectedDataType: () => {},
  setSelectedRankingType: () => {},
  setTagList: () => {},
  setAppList: () => {},
  setAllAppList: () => {},
  setAllTagsAppList: () => {},
  setCompetitorAppList: () => {},
  setCountryList: () => {},
  setLanguageList: () => {},
  setMarketList: () => {},
  setDeviceList: () => {},
  setDataPeriods: () => {},
  setDataTypes: () => {},
  setRankingTypes: () => {},
  setTagKeywordData: () => {},
  setFetchedHtml: () => {},
  setUserSubscriptions: () => {},
  setUserStats: () => {},
  setUserData: () => {},
  setValidSubscription: () => {},
  setTriggerAction: () => {},
  setLoggedin: () => {},
  setSelectedTab: () => {},
});
