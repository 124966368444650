import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('react_app_container');
const root = createRoot(container);
//<React.StrictMode>
root.render(
    
        <BrowserRouter>
            <App />
        </BrowserRouter>
    
);
